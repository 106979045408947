<template>
  <v-footer padless>
    <v-card flat class="" width="100%">
      <v-card-text class="white--text">
        <div style="margin: auto 0; text-align: center">
          <v-btn plain :to="'/policy/terms'"> 이용약관 </v-btn>
          <v-btn plain :to="'/policy/privacy'"> 개인정보 처리방침 </v-btn>
          <!--
          <v-btn plain :to="'/service'"> 서비스 안내 </v-btn>
          <v-btn plain :to="'/service'"> 1:1 문의 </v-btn>
          <v-btn plain :to="'/customer'"> 고객 지원 </v-btn>
          -->
        </div>
      </v-card-text>

      <v-divider></v-divider>



      <div v-html="$store.state.systemOption.service_footer"></div>

      <!-- 고정값
      <div class="caption mt-5 text--disabled text-center">
        당사의 분석시스템은 누적패턴을 분석/필터링한 정보제공만을 목적으로 하며,
        당첨확률 개선서비스가 아니므로 서비스 이용 과정에서 기대이익을 얻지
        못하거나 발생한 손해 등에 대한 최종책임은 서비스 이용자 본인에게
        있습니다.
      </div>
      <v-card-text class="gray--text text-center">
        <div class="caption text-center mt-3 text--disabled">
          로또7777 사업자등록번호: 123-123-123456 통신판매업허가번호:
          2020-서울서초-0309<br />
          서울시 송파구 송파대로20길 4, 헤브론복합빌딩 302(문정동) 전화:
          1600-7777<br />
          Copyright © {{ new Date().getFullYear() }} 로또7777 All right
          reserved.
        </div>
      </v-card-text>
    -->
    </v-card>

  </v-footer>
</template>
<script>
export default {
  name: "Footer",

  data: () => ({}),
};
</script>
