<template>
  <div>
    <form @submit.prevent="formSubmit" method="post">
      <v-card
        :class="$vuetify.breakpoint.smAndUp ? 'mx-auto ' : 'mx-auto'"
        elevation="0"
        outlined
      >
        <v-skeleton-loader
          type="text"
          class="pa-5 pb-3 ma-0"
          v-if="loading"
        ></v-skeleton-loader>

        <v-skeleton-loader
          type="paragraph"
          class="pa-5 pb-3 ma-0"
          v-if="loading"
        ></v-skeleton-loader>

        <v-row justify="space-between" no-gutters>
          <v-textarea
            v-if="loading === false"
            v-model="content"
            solo
            flat
            label=""
            placeholder="댓글을 남겨보세요."
            hide-details
            auto-grow
            class="text-subtitle-2"
            rows="3"
            row-height="15"
          ></v-textarea>

          <v-btn
            v-if="button === 'side'"
            color="grey lighten-3"
            depressed
            class="ma-3"
            type="submit"
            width="60"
            height="60"
          >
            <span v-if="mode === 'edit'">수정</span>
            <span v-else>등록</span>
          </v-btn>
        </v-row>

        <v-divider></v-divider>
        <div class="pa-3">
          <FileUploader
            ref="uploader"
            :files="gallery"
            @files-event="getFileRecords"
            :max="3"
          >
          </FileUploader>
        </div>
      </v-card>

      <v-btn
        v-if="button === 'bottom'"
        block
        color="primary"
        depressed
        type="submit"
        height="45"
        class="mt-4"
      >
        <span v-if="mode === 'edit'">수정</span>
        <span v-else>등록</span>
      </v-btn>
    </form>
  </div>
</template>
<script>
import http from "../http";
//import Like from "./Like";

export default {
  name: "classlistgrid",
  title: "classlistgrid",
  props: {
    id: { type: String, required: false, default: "" }, //comment id
    post: { type: Number, required: true }, //post id
    mode: { type: String, required: false, default: "" },
    button: { type: String, required: false, default: "side" },
  },
  components: {
    //Like,
  },
  data() {
    return {
      category: null,
      title: null,
      content: null,
      tag: null,
      images: null,

      gallery: null,

      fileRecords: null,

      loading: true,
      firstLoad: true,

      autoUpload: false,
    };
  },
  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.getContent();

    console.log(this.firstLoad);
  },
  watch: {
    id() {
      this.getContent();
    },

    $route(to, from) {
      if (to.path != from.path) {
        /* router path가 변경될 때마다 */

        this.getContent();
      }
    },
  },
  computed: {
    // 계산된 getter
    isCanNext: function () {
      // `this` 는 vm 인스턴스를 가리킵니다.

      let isCanNext = this.thispage < this.lastpage;
      return isCanNext;
    },
    parentUrl: function () {
      if (this.from) {
        return this.from.path;
      } else {
        return `/experts/${this.item.fields.category.slug}/${this.item.id}`;
      }
    },
    uploadRecords: function () {
      let uploadRecords = [];
      if (this.fileRecords) {
        this.fileRecords.forEach(function (item) {
          if (item.id) {
            uploadRecords.push(item.id);
          } else {
            let _data = item.upload["data"];
            uploadRecords.push(_data);
          }
        });
      }

      return uploadRecords;
    },
  },
  methods: {
    getFileRecords(fileRecords) {
      //console.log('parent');
      //console.log(fileRecords);
      this.fileRecords = fileRecords;
    },

    async formSubmit() {
      if (this.content.length < 5) {
        let alertDialogInfo = {
          timeout: 500,
          //emoji: "😆",
          title: "내용을 5자 이상 입력해주세요.",
          // thirdLineText: "셋째줄",
        };
        this.$store.dispatch("openAlertDialog", alertDialogInfo);
        return;
      }

      if (this.uploadRecords.includes(null)) {
        let alertDialogInfo = {
          timeout: 1000,
          //emoji: "😆",
          title: "파일 업로드 중입니다.",
          secondLineText: "잠시 후 다시 시도해주세요.",
          // thirdLineText: "셋째줄",
        };
        this.$store.dispatch("openAlertDialog", alertDialogInfo);

        this.autoUpload = true;

        return;
      }

      let postObj = {
        //title: this.title,
        //status: "publish",
        content: this.content,
        post: this.post,
        fields: {
          gallery: this.uploadRecords,
        },
      };

      let commentId = this.id;
      if (commentId === "new") {
        commentId = "";
      }

      this.$store.dispatch("openAlertDialog", {
        timeout: 1000,
        //emoji: "😆",
        title: "저장 중...",
        //firstLineText: "도움에 감사드립니다",
        //secondLineText: "",
        // thirdLineText: "셋째줄",
        persistent: true,
      });

      http
        .post("/api/v1/comment/" + commentId, postObj)
        .then((response) => {
          this.$store.dispatch("closeAlertDialog");

          console.log(response);
          if (response.data.success) {
            let alertDialogInfo = {
              timeout: 500,
              //emoji: "😆",
              title: "저장되었습니다.",
              //firstLineText: "도움에 감사드립니다",
              //secondLineText: "",
              // thirdLineText: "셋째줄",
              //persistent:true,
            };
            this.$store.dispatch("openAlertDialog", alertDialogInfo);

            this.$emit("refresh-event");

            this.content = "";
            this.gallery = [];

            //this.$router.go(-1);

            //this.$router.push(`/experts/${this.category}`);
          } else {
            let alertDialogInfo = {
              timeout: 1000,
              //emoji: "😆",
              title: "글 작성 권한이 없습니다.",
              //firstLineText: "도움에 감사드립니다",
              //secondLineText: "",
              // thirdLineText: "셋째줄",
            };
            this.$store.dispatch("openAlertDialog", alertDialogInfo);
          }
        })
        .catch((error) => {
          console.log(error);

          let alertDialogInfo = {
            timeout: 1000,
            //emoji: "😆",
            title: "글 작성 권한이 없습니다.",
            //firstLineText: "도움에 감사드립니다",
            //secondLineText: "",
            // thirdLineText: "셋째줄",
          };
          this.$store.dispatch("openAlertDialog", alertDialogInfo);
        });
    },

    getContent() {
      if (this.id === "new" || !this.$nvl(this.id)) {
        this.loading = false;
        this.firstLoad = false;
        this.content = "";
        return;
      }

      this.loading = true;
      this.$http
        .get("https://lotto7777.iwinv.net/api/v1/comment/" + this.id)
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            this.content = res.data.data.content;

            if (res.data.data.fields.gallery) {
              this.gallery = res.data.data.fields.gallery;
              //console.log(this.gallery);
            }

            //this.$router.go(0);

            //console.log(res.data.data.fields.tag);
            //console.log(this.tag);
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "GongGothicMedium";
  font-size: 40px;
}

.bt_download {
  /* 시그니처 */
  background: linear-gradient(275.28deg, #ec4480 -25.76%, #ffb629 100%);
  border-radius: 6px;
  font-weight: bold;
}
.is_signature {
  border-radius: 6px;
  border: 3px solid #ffa229;
}

.class_item {
  border-radius: 6px;
  border: 3px solid #00000000;
}
.class_item_signature {
  border-radius: 6px;
  border: 3px solid #ffa229;
  border-style: inset;
}

.signature_tag {
  /* 시그니처 */
  left: 3px;
  border-radius: 0 !important;
}
</style>
