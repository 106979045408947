var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"text-center",staticStyle:{"font-size":"20px","color":"#333","width":"100%"},attrs:{"cols":"auto"}},_vm._l((_vm.number),function(num,ii){return _c('v-chip',{key:ii,class:_vm.$vuetify.breakpoint.smAndUp
          ? 'itemLottoNumber large'
          : 'itemLottoNumber',style:(num > 40 && _vm.number.find(function (value) { return value === num; })
          ? 'color:#00000070'
          : num > 30 && _vm.number.find(function (value) { return value === num; })
          ? 'color:#00000070'
          : num > 20 && _vm.number.find(function (value) { return value === num; })
          ? 'color:#00000070'
          : num > 10 && _vm.number.find(function (value) { return value === num; })
          ? 'color:#00000070'
          : num <= 10 && _vm.number.find(function (value) { return value === num; })
          ? 'color:#00000070'
          : 'color:#00000070'),attrs:{"value":num,"color":num > 40
          ? '#b0d840'
          : num > 30
          ? '#aaa'
          : num > 20
          ? '#ff7272'
          : num > 10
          ? '#69c8f2'
          : num <= 10
          ? '#fbc400'
          : ''}},[_vm._v(" "+_vm._s(num)+" ")])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }