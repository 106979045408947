<template>
<v-responsive>
  <v-row class="py-1">
    <v-col class="d-flex align-center" style="color:#333;font-size:17px;font-weight:bold;" v-html="title">
      
    </v-col>
    <v-col>
      
        <v-select  v-if="winner_result_no"
        style="width:90px;float:right;padding:0;font-size:12px;"
          :items="winner_result_data"
          v-model="winner_result_no"
          :value="winner_result_no"
          
              item-value="drwNo"
              item-text="drwNo"
              
          hide-details
          class="my-2 pa-0 small"
          dense outlined
          small
          max-width="100"
          height="10"
        ></v-select>
      
        <v-select v-if="false"
        style="width:90px;float:right;padding:0;font-size:12px;"
          :items="items2"
          value="975"
          hide-details
          class="my-2 pa-0 small"
          dense outlined
          small
          max-width="100"
          height="10"
        ></v-select>
        <!--
        <select style="background:#fff;border:1px solid #eee;padding:2px 5px;border-radius:14px;font-size:11px;;">
          <option>975회</option>
          <option>975회</option>
          <option>975회</option>
        </select>
        -->
    </v-col>

  </v-row>

 <v-simple-table dense v-if="winner_result_no">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="datatable_header" style="width:30%">
            순위
          </th>
          <th class="text-right datatable_header" style="width:20%">
            당첨 수
          </th>
          <th class="text-right datatable_header">
            총 당첨금액
          </th> 
        </tr>
      </thead>
      <tbody>
        <tr
        >
          <td>1등</td>
          <td class="text-right" v-if="service_result_data.winner1_count>0">{{service_result_data.winner1_count}}</td>
          <td class="text-right" v-else>-</td>
          
          <td class="text-right" v-if="service_result_data.winner1_prize>0">{{$numberToKorean(service_result_data.winner1_prize)}}원</td>
          <td class="text-right" v-else>-</td>
        </tr>
        <tr
        >
          <td>2등</td>
          <td class="text-right" v-if="service_result_data.winner2_count>0">{{service_result_data.winner2_count}}</td>
          <td class="text-right" v-else>-</td>
          <td class="text-right" v-if="service_result_data.winner2_prize>0">{{$numberToKorean(service_result_data.winner2_prize)}}원</td>
          <td class="text-right" v-else>-</td>
        </tr>
        <tr
        >
          <td>3등</td>
          <td class="text-right" v-if="service_result_data.winner3_count>0">{{service_result_data.winner3_count}}</td>
          <td class="text-right" v-else>-</td>
          <td class="text-right" v-if="service_result_data.winner3_prize>0">{{$numberToKorean(service_result_data.winner3_prize)}}원</td>
          <td class="text-right" v-else>-</td>
        </tr>
        <tr
        >
          <td>4등</td>
          <td class="text-right" v-if="service_result_data.winner4_count>0">{{service_result_data.winner4_count}}</td>
          <td class="text-right" v-else>-</td>
          <td class="text-right" v-if="service_result_data.winner4_prize>0">{{$numberToKorean(service_result_data.winner4_prize)}}원</td>
          <td class="text-right" v-else>-</td>
        </tr>
        <tr
        >
          <td>5등</td>
          <td class="text-right" v-if="service_result_data.winner5_count>0">{{service_result_data.winner5_count}}</td>
          <td class="text-right" v-else>-</td>
          <td class="text-right" v-if="service_result_data.winner5_prize>0">{{$numberToKorean(service_result_data.winner5_prize)}}원</td>
          <td class="text-right" v-else>-</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>












  <v-data-table
    dense v-if="false"
    :headers="headers"
    :items="desserts"
    item-key="name"
    class="elevation-0"
    hide-default-footer
    :mobile-breakpoint="0" 

  ></v-data-table>
</v-responsive>
</template>
<script>
  export default {
  props: {
    title: String,
  },
    data: () => ({
      desserts: [
        {
          grade: '1등',
          type: '1조합',
          amount: '2,440,410,375원',
        },
        {
          grade: '2등',
          type: '3조합',
          amount: '2,440,410,375원',
        },
        {
          grade: '3등',
          type: '122조합',
          amount: '2,440,410,375원',
        },
        {
          grade: '4등',
          type: '6,672조합',
          amount: '2,440,410,375원',
        },
        {
          grade: '5등',
          type: '12,5413조합',
          amount: '2,440,410,375원',
        },
      ],
      headers: [
        {
          text: '등수',
          align: 'start',
          sortable: false,
          value: 'grade',
          class: "datatable_header"
        },
        { text: '당첨조합', value: 'type', align: 'end', 
          class: "datatable_header",
          sortable: false,},
        { text: '당첨금', value: 'amount' , align: 'end',
          class: "datatable_header",
          sortable: false,},
      ],
      items2: ['975', '974', '973', '972'],


      winner_result_no:null,
      winner_result_data:null,
      //service_result_data:null,
    }),
  computed: {
    result_data: function () {
      // `this` 는 vm 인스턴스를 가리킵니다.
        if(this.winner_result_data){
          return this.winner_result_data.find((item) => item.drwNo === this.winner_result_no);
        }else{
          return []
        }
        
    },
    service_result_data: function () {
      // `this` 는 vm 인스턴스를 가리킵니다.
      
          return this.$store.state.service_result[this.winner_result_no];


    },
  },
  mounted() {
    //페이지 시작하면은 자동 함수 실행
    setTimeout(() => {
      
    this.winner_result_data = [...this.$store.state.winner_result].reverse();
    this.winner_result_no = this.winner_result_data[0]['drwNo'];

    this.winner_result_data = this.winner_result_data.filter((item) => item.drwNo >= 1040);


    }, 300);


    //this.winner_result_data[]


  },
  }
</script>
<style scoped>

.itemLottoNumber {
  display: inline-block;
  text-align: center;
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0;
  margin-right: 3px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
}
.itemLottoNumber.large {
  margin: 5px auto;
  height: 45px;
  width: 45px;
  margin-right: 10px;
  border-radius: 50%;
  font-size: 20px;
}
</style>
