<template>
<v-responsive>
  <v-row class="py-1">
    <v-col class="d-flex align-center" style="color:#333;font-size:17px;font-weight:bold;" v-html="title">
      
    </v-col>
    <v-col>
      
        <!--
        <select style="background:#fff;border:1px solid #eee;padding:2px 5px;border-radius:14px;font-size:11px;;">
          <option>975회</option>
          <option>975회</option>
          <option>975회</option>
        </select>
        -->
    </v-col>

  </v-row>


    <a href="tel:0000-0000" style="color:#1428a0;font-size:40px;text-decoration:none;font-weight:bold;">
      1600-7888
    </a>

 <div>
   <strong>평일 09:00 ~ 18:00 (점심12시 ~ 1시 제외)</strong><br>
주말 및 공휴일 고객센터 휴무

 </div>

</v-responsive>
</template>
<script>
  export default {
  props: {
    title: String,
  },
    data: () => ({
      desserts: [
        {
          grade: '1등',
          type: '1조합',
          amount: '2,440,410,375원',
        },
        {
          grade: '2등',
          type: '3조합',
          amount: '2,440,410,375원',
        },
        {
          grade: '3등',
          type: '122조합',
          amount: '2,440,410,375원',
        },
        {
          grade: '4등',
          type: '6,672조합',
          amount: '2,440,410,375원',
        },
        {
          grade: '5등',
          type: '12,5413조합',
          amount: '2,440,410,375원',
        },
      ],
      headers: [
        {
          text: '등수',
          align: 'start',
          sortable: false,
          value: 'grade',
          class: "datatable_header"
        },
        { text: '당첨조합', value: 'type', align: 'end', 
          class: "datatable_header",
          sortable: false,},
        { text: '당첨금', value: 'amount' , align: 'end',
          class: "datatable_header",
          sortable: false,},
      ],
      items2: ['975', '974', '973', '972'],
    }),
  }
</script>
<style scoped>

.itemLottoNumber {
  display: inline-block;
  text-align: center;
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0;
  margin-right: 3px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
}
.itemLottoNumber.large {
  margin: 5px auto;
  height: 45px;
  width: 45px;
  margin-right: 10px;
  border-radius: 50%;
  font-size: 20px;
}
</style>
