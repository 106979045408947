<template>
  <div>
    <v-container
      :fluid="$vuetify.breakpoint.lgAndDown"
      v-if="$vuetify.breakpoint.lgAndUp"
      style="max-width:1440px;"
    >
      <v-card class="d-flex justify-space-between" flat tile>
        <v-card class="pt-2 pb-2" flat tile>
          <a :href="'/'" centered style="float:left;">
            <v-img
              alt="Vuetify Logo"
              class="shrink mr-2 ml-2"
              contain
              src="@/assets/logo_lotto7.png"
              transition="scale-transition"
              width="200"
          /></a>



              <!-- service_banner_global_header S -->
              <template v-if="$store.state.systemOption && $store.state.systemOption.service_banner_global_header ">
                <div style="float:left;padding-left:15px; padding-top:5px;">
                  <div v-for="(item, i) in $store.state.systemOption.service_banner_global_header" 
                      :key="i + $randomNumber()">
                        <div v-html="item.code">
                        </div>
                        <a 
                        v-if="item.src && item.url"
                        :href="item.url"
                        :target="item.target">
                        <v-img
                            class="shrink"
                            contain
                            :src="item.src"
                            full-width
                          />
                        </a>
                  </div>
                </div>
              </template>
              <!-- service_banner_global_header E -->




        </v-card>
        <v-card class="pa-2" flat tile>
          <div style="float:left;padding-left:15px; padding-top:5px;" v-if="latestLottoData">

          <strong>{{latestLottoData.drwNo}}회 당첨번호</strong> <small class="text--disabled">/ {{latestLottoData.drwNoDate}}</small><br>
          <WidgetLottoNumber :number="lottoNumbers" :bonus="lottoBonus" style="text-align:center;" viewtype="small"></WidgetLottoNumber>


          </div>

        </v-card>

        <v-card class="pt-5" flat tile v-if="false">
          <v-btn depressed :to="'/join'" class="ml-3" v-if="!isAuthenticated">
            회원가입
          </v-btn>
          <v-btn depressed :to="'/login?redirect='+this.$route.path" class="ml-3" v-if="!isAuthenticated">
            로그인
          </v-btn>
          <v-btn depressed :to="'/mypage'" class="ml-3" v-if="isAuthenticated">
            마이페이지
          </v-btn>
          <v-btn
            depressed
            @click="logout()"
            class="ml-3"
            v-if="isAuthenticated"
          >
            로그아웃
          </v-btn>
        </v-card>


      </v-card>
    </v-container>

    <div class="blue darken-4 bx_topmenu" v-if="$vuetify.breakpoint.lgAndUp">
      <v-container class="pt-0 pb-0" :fluid="$vuetify.breakpoint.lgAndDown"
      style="max-width:1440px;">
        <v-row align="center" justify="space-between" class="" no-gutters>
          <v-col cols="auto">
            <v-btn
              v-for="(item, i) in topmenu"
              :key="i"
              :to="{ path: item.url }"
              x-large
              tile
              depressed
              dark
              color="blue darken-4"
              >{{ item.text }}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>


          <v-btn
          v-if="false"
            x-large
            tile
            depressed
            dark
            color="blue darken-4"
            :to="'/service'"
            >서비스 안내
          </v-btn>
          <v-btn
          v-if="false"
            x-large
            tile
            depressed
            dark
            color="blue darken-4"
            :to="'/customer'"
            >고객 지원
          </v-btn>
          <v-btn
            x-large
            tile
            depressed
            dark
            color="blue darken-4"
            :to="'/store/top'"
            >로또 판매점
          </v-btn>
          <v-btn
            x-large
            tile
            depressed
            dark
            color="blue darken-4"
            :to="'/store/buyinfo'"
            >구입요령
          </v-btn>
        </v-row>
      </v-container>
    </div>

    <v-app-bar
      v-if="$vuetify.breakpoint.mdAndDown"
      
      hide-on-scroll
      elevate-on-scroll
      color="white"
    >
      <div class="d-flex align-center">
        <a :href="'/'">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2 ml-0 mt-1"
            contain
            src="@/assets/logo_lotto7.png"
            transition="scale-transition"
            width="150"
        /></a>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        class="mr-2"
        plain
        icon
        small
        :to="'/lottowin/check/'"
      >
        <v-icon>mdi-clipboard-list-outline</v-icon> <span v-if="$vuetify.breakpoint.lgAndUp">당첨확인</span>
      </v-btn>

      <v-btn v-if="false"
        class="mr-0"
        plain
        icon
        small
        :to="'/mypage'"
      >
        <v-icon overlap>mdi-account-circle-outline</v-icon> <span v-if="$vuetify.breakpoint.lgAndUp">마이페이지</span>
      </v-btn>
    </v-app-bar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
export default {
  name: "Footer",

  data: () => ({
    topmenu: [
      { text: "오늘의 행운번호 받기", url: "/analysis/auto" },
      { text: "당첨결과 ", url: "/lottowin/check/" },
      //{ text: "판매점 ", url: "/store/top" },
      //{ text: "빅데이터 추출번호", url: "/bigdata/gold" },
      //{ text: "로또 명인", url: "/experts/free" },
      //{ text: "행운 번호", url: "Fortune" },
      //{ text: "고객센터", url: "Customer" },
    ],
    lottoNumbers:  null,
    lottoBonus:  null,

    latestLottoData: null,
  }),

  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.getLatestResult();
  },

  computed: {
    ...mapGetters(["isAuthenticated"]),
    //...mapGetters(["menuLeft"]),

    ...mapState(["alertDialogToggle", "alertDialogInfo"]),
  },
  methods: {
    redirect() {
      console.log("redirect");
      console.log("isAuthenticated : " + this.isAuthenticated);
      if (!this.isAuthenticated) {
        /*
        this.$router.push({
          name: "Home",
        })
        */
        window.history.length > 2
          ? this.$router.go(-1)
          : this.$router.push("/");
      }
    },
    logout() {
      this.$store
        .dispatch("logout", {})
        .then(() => this.redirect())
        .catch(({ message }) => alert(message));
    },




    getLatestResult() {

      this.$axios
        .get(
          "//lotto7777.iwinv.net/lottoapp/?drwNo=lastest&mode=get"
        )
        .then((res) => {
          console.log(res);

          this.latestLottoData = res.data;

          this.lottoNumbers = [
            this.latestLottoData['drwtNo1'],
            this.latestLottoData['drwtNo2'],
            this.latestLottoData['drwtNo3'],
            this.latestLottoData['drwtNo4'],
            this.latestLottoData['drwtNo5'],
            this.latestLottoData['drwtNo6']
            ];

          this.lottoBonus = this.latestLottoData['bnusNo'];

        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });

    },




  },
};
</script>
