<template>
  <v-responsive>
    <v-container
      :fluid="$vuetify.breakpoint.lgAndDown"
      no-gutters
      class="mt-0 mb-3 pa-0"
      style="max-width: 1440px"
    >

    <!-- 메인 상단 S -->
      <v-row no-gutters class="my-0 mx-0" style="background: #227dbd">
        <v-col cols="12" xs="12"  sm="12" md="12" lg="9">

          <!-- 메인 배너 S
          <v-img
            alt="Vuetify Logo"
            class="shrink"
            contain
            src="@/assets/tmp_main_banner.png"
            full-width
          />
          <a href="/" style="width:100%;line-height:0">
<img src="/img/tmp_main_banner.b29edbd3.png" style="width:100%;line-height:0" /></a>
           메인배너 E -->



              <!-- service_banner_home_side_service_banner_maintop S -->
              <template v-if="$store.state.systemOption && $store.state.systemOption.service_banner_main ">
                <div v-for="(item, i) in $store.state.systemOption.service_banner_main" 
                     :key="i + $randomNumber()">
                      <div v-html="item.code">
                      </div>
                      
                      <a :href="item.url" :target="item.target" style="width:100%;line-height:0">
                      <img :src="item.src" style="width:100%;line-height:0;display:block;" /></a>

                </div>
              </template>
              <!-- service_banner_main E -->




        </v-col>
        <v-col  xs="12" sm="12" md="3" class="d-flex align-center justify-center">


          <!-- 메인 당첨결과 위젯 S -->
          <div style="padding: 20px 0; text-align: center; color: #fff" v-if="latestLottoData">
            <div style="font-size: 20px;font-weight:bold;">{{latestLottoData.drwNo}}회 당첨결과</div>
            <div><small>{{latestLottoData.drwNoDate}}</small></div>

            <div style="margin-top: 15px">
                  <WidgetLottoNumber :number="lottoNumbers"  :bonus="lottoBonus" style="text-align:center;" viewtype="small"></WidgetLottoNumber>
            </div>
            <div
              style="
                color: #;
                border: 1px solid #368ac6;
                width: 272px;
                margin: 20px auto;
              "
            >
              <div style="background: #11629c; line-height: 36px">
                1등 총 당첨금
              </div>
              <div style="width: 280px; line-height: 60px; margin: 0 auto">
                <strong style="font-size: 20px">{{$numberToKorean(latestLottoData.firstAccumamnt,100000000)}}원</strong> 
                ({{latestLottoData.firstPrzwnerCo}}명 / {{ $numberToKorean(latestLottoData.firstWinamnt,100000000)}} )
              </div>
            </div>

            <v-row no-gutters style="width: 280px; margin: 20px auto">
              <v-col cols="6"><v-btn depressed color="grey darken-3 white--text" to="/lottowin/result">회차별당첨결과 </v-btn> </v-col>
              <v-col cols="6"><v-btn depressed color=" white--text" style="background:#0a857e;" to="/analysis/auto">행운번호받기 </v-btn> </v-col>
            </v-row>
          </div>
          <!-- 메인 당첨결과 위젯 E -->



        </v-col>
      </v-row>
    <!-- 메인 상단 S -->





    <!-- 메인 중단 S -->
      <v-row no-gutters  class="my-0 mx-0" style="background: #fff">
        <!-- 메인 중단 좌 S -->
        <v-col  xs="12"  sm="12" md="9">



              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col>
                    <!-- service_banner_home_top S -->
                    <template v-if="$store.state.systemOption && $store.state.systemOption.service_banner_home_top ">
                      <div v-for="(item, i) in $store.state.systemOption.service_banner_home_top" 
                          :key="i + $randomNumber()">
                            <div v-html="item.code">
                            </div>
                            <a 
                            v-if="item.src && item.url"
                            :href="item.url"
                            :target="item.target">
                            <v-img
                                class="shrink"
                                contain
                                :src="item.src"
                                full-width
                              />
                            </a>
                      </div>
                    </template>
                    <!-- service_banner_home_top E -->
                </v-col>
              </v-row>









                  
              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col  xs="12"  sm="12" md="6" lg="6">
                      <!-- 로또7성적표 S-->
                      <DataTable title="<strong style='color:#00234d;margin-right:5px;'>로또7</strong> 성적표"/>
                      <!-- 로또7성적표 E -->
                </v-col>
                <v-col  xs="12"  sm="12" md="6" lg="6">
                      <!-- 로또7 당첨자 목록 S-->
                      <DataTableWinner title="<strong style='color:#00234d;margin-right:5px;'>로또7</strong> 당첨금액"/>
                      <!-- 로또7 당첨자 목록 E -->
                </v-col>
              </v-row>


              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col  xs="12"  sm="12" md="6">      
                  <!--당첨자목록 S-->
                      <v-row class="py-2">
                        <v-col class="d-flex align-center" style="color:#333;font-size:17px;font-weight:bold;">
                          
                             <strong style='color:#00234d;margin-right:5px;'>행운번호 받기</strong> 
                        </v-col>
                        <v-col>
                          
                        </v-col>

                      </v-row>
                      <router-link to="/analysis/auto">
                        <v-img
                          alt="Vuetify Logo"
                          class="shrink mt-1"
                          contain
                          src="@/assets/tmp_sub_banner1.png"
                          full-width
                          to="/analysis/auto"
                        />
                      </router-link>
                  <!--당첨자목록 E -->
                </v-col>
                <v-col  xs="12"  sm="12" md="6">    
                  <!-- 조합도구 S -->  
                      <v-row class="py-2">
                        <v-col class="d-flex align-center" style="color:#333;font-size:17px;font-weight:bold;">
                          
                             <strong style='color:#00234d;margin-right:5px;'>행운번호 조합기</strong> 
                        </v-col>
                        <v-col>
                          
                        </v-col>

                      </v-row>
                      <router-link to="/analysis/fixed7">
                        <v-img
                          alt="Vuetify Logo"
                          class="shrink mt-1"
                          contain
                          src="@/assets/tmp_sub_banner2.png"
                          full-width
                        />
                      </router-link>
                  <!--조합도구 E -->
                </v-col>
              </v-row>


              <v-row class="my-0 mx-0" style="background: #fff" v-if="false">
                <v-col cols="12" xs="12"  sm="12" md="6">      
                  <!--자유토론방 S-->
                  
                      <v-row class="py-1">
                        <v-col class="d-flex align-center" style="color:#333;font-size:17px;font-weight:bold;">
                          
                             <strong style='color:#00234d;margin-right:5px;'>자유토론방 </strong> 
                        </v-col>
                        <v-col class="d-flex align-center justify-end">
                      
                                <v-btn depressed small text to="/experts/free">더보기 
                                      <v-icon right>
                                        mdi-chevron-right
                                      </v-icon></v-btn> 
                          
                        </v-col>

                      </v-row>
                      <WidgetBoard category="free" />
                  <!--자유토론방 E -->
                </v-col>
                <v-col  cols="12" xs="12"  sm="12" md="6">      
                  <!--전문가분석 S-->
                  
                      <v-row class="py-1">
                        <v-col class="d-flex align-center" style="color:#333;font-size:17px;font-weight:bold;">
                          
                             <strong style='color:#00234d;margin-right:5px;'>전문가분석 </strong> 
                        </v-col>
                        <v-col class="d-flex align-center justify-end">
                      
                                <v-btn depressed small text to="/experts/expert">더보기 
                                      <v-icon right>
                                        mdi-chevron-right
                                      </v-icon></v-btn> 
                          
                        </v-col>

                      </v-row>
                      <WidgetBoard category="expert" />
                  <!--전문가분석 E -->
                </v-col>
              </v-row>



              <v-row class="my-0 mx-0" style="background: #fff" v-if="false">
                <v-col cols="12" xs="12"  sm="12" md="12">      
                  <!--당첨 후기 실시간 현황 S-->
                  
                      <v-row class="py-1">
                        <v-col class="d-flex align-center" style="color:#333;font-size:17px;font-weight:bold;">
                          
                             <strong style='color:#00234d;margin-right:5px;'>당첨 후기  </strong> 실시간 현황
                        </v-col>
                        <v-col class="d-flex align-center justify-end">
                      

                                <v-btn depressed small text to="/experts/free" v-if="false">더보기 
                                      <v-icon right>
                                        mdi-chevron-right
                                      </v-icon></v-btn> 
                          
                        </v-col>

                      </v-row>


                      <v-row class="py-0 ma-0">
                        <v-col cols="3" lg="2">
                                <v-img
                                  alt="Vuetify Logo"
                                  class="shrink"
                                  contain
                                  src="@/assets/tmp_main_win_gallery.png"
                                  full-width
                                />
                                <div style="margin-top:10px;">
                                <strong>896회 1등 30억 와... </strong><br>
                                <small>2020-02-02</small>
                                </div>
                        </v-col>
                        <v-col cols="3" lg="2">
                                <v-img
                                  alt="Vuetify Logo"
                                  class="shrink"
                                  contain
                                  src="@/assets/tmp_main_win_gallery.png"
                                  full-width
                                />
                                <div style="margin-top:10px;">
                                <strong>896회 1등 30억 와... </strong><br>
                                <small>2020-02-02</small>
                                </div>
                        </v-col>
                        <v-col cols="3" lg="2">
                                <v-img
                                  alt="Vuetify Logo"
                                  class="shrink"
                                  contain
                                  src="@/assets/tmp_main_win_gallery.png"
                                  full-width
                                />
                                <div style="margin-top:10px;">
                                <strong>896회 1등 30억 와... </strong><br>
                                <small>2020-02-02</small>
                                </div>
                        </v-col>
                        <v-col cols="3" lg="2">
                                <v-img
                                  alt="Vuetify Logo"
                                  class="shrink"
                                  contain
                                  src="@/assets/tmp_main_win_gallery.png"
                                  full-width
                                />
                                <div style="margin-top:10px;">
                                <strong>896회 1등 30억 와... </strong><br>
                                <small>2020-02-02</small>
                                </div>
                        </v-col>
                        <v-col cols="3" lg="2" v-if="$vuetify.breakpoint.lgAndUp">
                                <v-img
                                  alt="Vuetify Logo"
                                  class="shrink"
                                  contain
                                  src="@/assets/tmp_main_win_gallery.png"
                                  full-width
                                />
                                <div style="margin-top:10px;">
                                <strong>896회 1등 30억 와... </strong><br>
                                <small>2020-02-02</small>
                                </div>
                        </v-col>
                        <v-col cols="3" lg="2" v-if="$vuetify.breakpoint.lgAndUp">
                                <v-img
                                  alt="Vuetify Logo"
                                  class="shrink"
                                  contain
                                  src="@/assets/tmp_main_win_gallery.png"
                                  full-width
                                />
                                <div style="margin-top:10px;">
                                <strong>896회 1등 30억 와... </strong><br>
                                <small>2020-02-02</small>
                                </div>
                        </v-col>

                      </v-row>


                  <!--당첨 후기 실시간 현황 E -->
                </v-col>
              </v-row>
              

              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col>
              <!-- service_banner_home_bottom S -->
              <template v-if="$store.state.systemOption && $store.state.systemOption.service_banner_home_bottom ">
                <div v-for="(item, i) in $store.state.systemOption.service_banner_home_bottom" 
                     :key="i + $randomNumber()">
                      <div v-html="item.code">
                      </div>
                      <a 
                      v-if="item.src && item.url"
                      :href="item.url"
                      :target="item.target">
                      <v-img
                          class="shrink"
                          contain
                          :src="item.src"
                          full-width
                        />
                      </a>
                </div>
              </template>
              <!-- service_banner_home_bottom E -->
              </v-col>
              </v-row>

        </v-col>
        <!-- 메인 중단 좌 E -->





        <!-- 메인 중단 우 S -->
        <v-col  xs="12" sm="12" md="3" class="mt-0 pt-0">


              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col >
                  <!-- service_banner_home_side_top S -->
                  <template v-if="$store.state.systemOption && $store.state.systemOption.service_banner_home_side_top ">
                    <div v-for="(item, i) in $store.state.systemOption.service_banner_home_side_top" 
                        :key="i + $randomNumber()">
                          <div v-html="item.code">
                          </div>
                          <a 
                          v-if="item.src && item.url"
                          :href="item.url"
                          :target="item.target">
                          <v-img
                              class="shrink"
                              contain
                              :src="item.src"
                              full-width
                            />
                          </a>
                    </div>
                  </template>
                  <!-- service_banner_home_side_top E -->
                </v-col>
              </v-row>
          

              <v-row class="my-0 mx-0 pt-0" style="background: #fff">
                <v-col  xs="12"  sm="12" md="12" class=" pt-0">
                              <WidgetPlayer title="<strong style='color:#00234d;margin-right:5px;'>로또645</strong> 추첨방송"/>
                </v-col>
              </v-row>


              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col  xs="12"  sm="12" md="12">
                        <WidgetAboutLotto title="<strong style='color:#00234d;margin-right:5px;'>로또645</strong> 기본상식"/>
                </v-col>
              </v-row>




            
              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col  xs="12"  sm="12" md="12">
                        <WidgetLottoFund title="<strong style='color:#00234d;margin-right:5px;'>로또645</strong>기금안내"/>
                </v-col>
              </v-row>
            
              <v-row class="my-0 mx-0" style="background: #fff" v-if="false">
                <v-col  xs="12"  sm="12" md="12">
                        <WidgetCustomer title="<strong style='color:#00234d;margin-right:5px;'>로또7</strong>고객센터"/>
                </v-col>
              </v-row>


              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col>
                  <!-- service_banner_home_side_bottom S -->
                  <template v-if="$store.state.systemOption && $store.state.systemOption.service_banner_home_side_bottom ">
                    <div v-for="(item, i) in $store.state.systemOption.service_banner_home_side_bottom" 
                        :key="i + $randomNumber()">
                          <div v-html="item.code">
                          </div>
                          <a 
                          v-if="item.src && item.url"
                          :href="item.url"
                          :target="item.target">
                          <v-img
                              class="shrink"
                              contain
                              :src="item.src"
                              full-width
                            />
                          </a>
                    </div>
                  </template>
                  <!-- service_banner_home_side_bottom E -->
                </v-col>
              </v-row>


        </v-col>
        <!-- 메인 중단 우 E -->



      </v-row>
    <!-- 메인 중단 S -->









<!-- test -->
      <v-row v-if="false">
        <v-col cols="12" sm="2">
          <v-sheet rounded="lg" min-height="268"> <!--  --> </v-sheet>
        </v-col>

        <v-col cols="12" sm="8">
          <v-sheet min-height="58vh" rounded="lg">
            <!--  -->

            <v-btn plain :to="'/payment'"> 결제 테스트 </v-btn>
          </v-sheet>
        </v-col>

        <v-col cols="12" sm="2">
          <v-sheet rounded="lg" min-height="268"> <!--  --> </v-sheet>
        </v-col>
      </v-row>
<!--test -->




    </v-container>
  </v-responsive>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "Home",

  metaInfo: {

    title: '로또7777777',

    titleTemplate: '%s | 로또7777777', // title 뒤에 붙일 공통된 이름 (주로 사이트이름) 

    htmlAttrs: {

      lang: 'ko' // i18n 사용하지 않는 경우 직접 입력

    },

    meta: [

      { charset: 'utf-8'},

      // SEO setting

      { name: 'description', content: '로또7777777'},

      { name: 'keywords', content: '로또,행운로또,행복로또,로또추출기,로또번호생성기,행운번호'},

      //{ name: 'author', content: 'test'},

      // SNS 용

      {

        property: 'og:title',

        content: '로또,행운로또,행복로또,로또추출기,로또번호생성기,행운번호',

        template: chunk => `${chunk} | 로또7777777`,

        vmid: 'og:title'

      },

      // mobile

      { name: 'viewport', content: 'width=device-width, initial-scale=1'}

    ]
  },
  components: {
    //HelloWorld,
  },
  data: () => ({

    lottoNumbers:  null,
    lottoBonus:  null,
    latestLottoData: null,


      
      }),
  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.getLatestResult();
  },
  methods: {
    getLatestResult() {

      this.$axios
        .get(
          "//lotto7777.iwinv.net/lottoapp/?drwNo=lastest&mode=get"
        )
        .then((res) => {
          console.log(res);

          this.latestLottoData = res.data;

          this.lottoNumbers = [
            this.latestLottoData['drwtNo1'],
            this.latestLottoData['drwtNo2'],
            this.latestLottoData['drwtNo3'],
            this.latestLottoData['drwtNo4'],
            this.latestLottoData['drwtNo5'],
            this.latestLottoData['drwtNo6']
            ];

          this.lottoBonus = this.latestLottoData['bnusNo'];

        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });

    },




  },
};
</script>
