<template>
<v-responsive v-if="drw">
  <v-row class="py-1">
    <v-col class="d-flex align-center" style="color:#333;font-size:17px;font-weight:bold;" v-html="title">
      
    </v-col>
    <v-col>
      
        <v-select
        style="width:90px;float:right;padding:0;font-size:12px;"
          :items="items"
          item-value="ContentNumber"
          item-text="ContentNumber"
          hide-details
          class="my-2 pa-0 small"
          dense outlined
          small
          max-width="70"
          height="10"
          v-model="drw"
          return-object
        ></v-select>
        <!--
        <select style="background:#fff;border:1px solid #eee;padding:2px 5px;border-radius:14px;font-size:11px;;">
          <option>975회</option>
          <option>975회</option>
          <option>975회</option>
        </select>
        -->
    </v-col>

  </v-row>


<a :href="'https://playvod.imbc.com/Templete/ClipView?bid='+drw.ClipId" target="_blank" style="text-decoration:none;" >
  <v-img
    alt="Vuetify Logo"
    class="shrink"
    contain
    :src="drw.ClipImg"
    gradient="to top right, rgba(0,0,0,.33), rgba(0,0,0,.7)"
    full-width
  >
      <template>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
        <v-icon style="color:#fff;font-size:50px;">mdi-play-circle-outline</v-icon>
        </v-row>
      </template>

  </v-img>
                      </a>



<div style="text-align:center;padding:10px 0;">
<strong>{{drw.ClipTitle}}</strong><br>
<small style="opacity:0.7;">방송일자: {{drw.RegDate}}</small>
</div>

</v-responsive>
</template>
<script>
  export default {
  props: {
    title: String,
  },
    data: () => ({
      desserts: [
        {
          grade: '1등',
          type: '1조합',
          amount: '2,440,410,375원',
        },
        {
          grade: '2등',
          type: '3조합',
          amount: '2,440,410,375원',
        },
        {
          grade: '3등',
          type: '122조합',
          amount: '2,440,410,375원',
        },
        {
          grade: '4등',
          type: '6,672조합',
          amount: '2,440,410,375원',
        },
        {
          grade: '5등',
          type: '12,5413조합',
          amount: '2,440,410,375원',
        },
      ],
      headers: [
        {
          text: '등수',
          align: 'start',
          sortable: false,
          value: 'grade',
          class: "datatable_header"
        },
        { text: '당첨조합', value: 'type', align: 'end', 
          class: "datatable_header",
          sortable: false,},
        { text: '당첨금', value: 'amount' , align: 'end',
          class: "datatable_header",
          sortable: false,},
      ],
      items2: ['975', '974', '973', '972'],


      items: null,
      drw: null,


    }),
  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.getLatestResult();
  },

  methods: {
    populateLicense(license) {
        this.trim.license = license
     },
    getLatestResult() {

      this.$axios
        .get(
          "//playvod.imbc.com/api/ProgramClipList?programId=1003945100000100000&curPage=1&pageSize=999"
        )
        .then((res) => {
          console.log(res);

          this.items = res.data['CList'];
          this.drw = this.items[0];

        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });

    },




  },


  }
</script>
<style scoped>

.itemLottoNumber {
  display: inline-block;
  text-align: center;
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0;
  margin-right: 3px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
}
.itemLottoNumber.large {
  margin: 5px auto;
  height: 45px;
  width: 45px;
  margin-right: 10px;
  border-radius: 50%;
  font-size: 20px;
}
</style>
