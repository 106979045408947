<template>
  <v-list flat min-height="268" class="pl-3">
    <v-list-item-group color="primary" mandatory>
      <v-list-item v-for="(item, i) in items" :key="i" :to="item.url">
        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {

  data: () => ({
    
    items: [

      { text: "당첨결과 조회", icon: "mdi-menu-right", url: "/lottowin/check" },
      //{ text: "회차별 성적표", icon: "mdi-menu-right" , url: "/lottowin/check"},
      { text: "회차별 당첨결과", icon: "mdi-menu-right" , url: "/lottowin/result"},
      { text: "당첨금 지급안내", icon: "mdi-menu-right" , url: "/lottowin/information"},
      { text: "당첨영상 다시보기", icon: "mdi-menu-right" , url: "/lottowin/video"},


      
    ],


  }),
  computed: {
  },
  methods: {
    
  },
};
</script>
