import axios from "axios";
import store from "../store";
//import jwt from "../common/jwt";
import { refreshToken } from "../service/login";

//axios.defaults.withCredentials = true;
const http = axios.create({
  baseURL: "//lotto7777.iwinv.net",
  headers: { "content-type": "application/json" },
});

http.interceptors.request.use(
  (config) => {
    const isAuthenticated = store.getters["isAuthenticated"];
    if (isAuthenticated) {
      config.headers.common["Authorization"] = store.getters["getAccessToken"];

      //config.headers.common["Authorization"] = jwt.getToken();
    }
    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

// Add a response interceptor
http.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log("에러일 경우", error.config);
    let errorAPI = error.config;

    console.log("errorAPI.retry s");
    console.log(errorAPI.retry);
    console.log(errorAPI);

    if (errorAPI.retry === undefined && store.getters["getAccessToken"]) {
      errorAPI.retry = true;
      console.log("토큰 오류일 경우");
      console.log(errorAPI);
      errorAPI["headers"]["Authorization"] = await refreshToken();
      console.log("업데이트");
      console.log(errorAPI);

      return http(errorAPI);
    }

    return Promise.reject(error);
  }
);

http.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

export default http;
