<template>
  <v-simple-table dense>
    <template v-if="category==='free'">
      <!--
      <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Calories
          </th>
        </tr>
      </thead>-->
      <tbody>
        <tr
          v-for="item in freeboard"
          :key="item.name"
        >
          <td style="padding-left:0;"><a :to="item.link" style="text-decoration:none;color:#333;">{{ item.title }}</a></td>
          
        </tr>
      </tbody>
    </template>
    <template v-if="category==='expert'">
      <!--
      <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Calories
          </th>
        </tr>
      </thead>-->
      <tbody>
        <tr
          v-for="item in expertboard"
          :key="item.name"
        >
          <td style="padding-left:0;"><a :to="item.link" style="text-decoration:none;color:#333;">{{ item.title }}</a></td>
          
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
  export default {
  props: {
    title: String,
    category: String,
  },
    data () {
      return {
        freeboard: [
          {
            title: '975회 3등 ㅎㅎㅎ 성공했네요',
            link: '#',
          },
          {
            title: '2등 아이가~ 2등 맞네!!!!',
            link: '#',
          },
          {
            title: '세금 떼고 9억 1천만원 받았습니다~',
            link: '#',
          },
          {
            title: '간절하면 이루어진다',
            link: '#',
          },
          {
            title: '3등 당첨됐어요 ^^',
            link: '#',
          },
        ],
        expertboard: [
          {
            title: '975회 당첨번호 분석',
            link: '#',
          },
          {
            title: '976회 예상 번호... 사견입니다.',
            link: '#',
          },
          {
            title: '여러분 이 번호 조합 잘 보세요.',
            link: '#',
          },
          {
            title: '흐름을 맞춰야 합니다.  2탄',
            link: '#',
          },
          {
            title: '흐름을 맞춰야 합니다. 1탄',
            link: '#',
          },

          /*
          975회 당첨번호 분석
976회 예상 번호... 사견입니다.
여러분 이 번호 조합 잘 보세요.
흐름을 맞춰야 합니다.  2탄
흐름을 맞춰야 합니다. 1탄
 */
        ],
      }
    },
  }
</script>
<style scoped>

.itemLottoNumber {
  display: inline-block;
  text-align: center;
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0;
  margin-right: 3px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
}
.itemLottoNumber.large {
  margin: 5px auto;
  height: 45px;
  width: 45px;
  margin-right: 10px;
  border-radius: 50%;
  font-size: 20px;
}
</style>
