<template>
  <v-bottom-navigation grow flat app color="primary">
    <v-btn to="/">
      <span>홈</span>
      <v-icon class="mdi-rotate-270">mdi-label-outline</v-icon>
    </v-btn>

    <v-btn to="/analysis/auto">
      <span>행운번호받기</span>
      <v-icon>mdi-clipboard-list-outline</v-icon>
    </v-btn>

    <v-btn to="/lottoWin/check" v-if="$vuetify.breakpoint.lgAndUp">
      <span>당첨결과</span>
      <v-icon>mdi-chart-gantt</v-icon>
    </v-btn>
    <v-btn to="/store/top">
      <span>판매점분석</span>
      <v-icon>mdi-bank</v-icon>
    </v-btn>
    <!--
    <v-btn to="/experts/free">
      <span>로또명인</span>
      <v-icon>mdi-certificate-outline</v-icon>
    </v-btn>
    <v-btn to="/mypage">
      <span>마이페이지</span>
      <v-icon>mdi-account-outline</v-icon>
    </v-btn>
    -->
  </v-bottom-navigation>
</template>
<script>
export default {
  name: "FooterMobile",

  data: () => ({}),

  mounted: () => {},
};
</script>
