<template>
<div>
        <!-- service_banner_global_sub_top S -->
        <template v-if="$store.state.systemOption && $store.state.systemOption.service_banner_global_sub_top ">
          <div v-for="(item, i) in $store.state.systemOption.service_banner_global_sub_top" 
              :key="i + $randomNumber()">
                <div v-html="item.code">
                </div>
                <a 
                v-if="item.src && item.url"
                :href="item.url"
                :target="item.target">
                <v-img
                    class="shrink"
                    contain
                    :src="item.src"
                    full-width
                  />
                </a>
          </div>
        </template>
        <!-- service_banner_global_sub_top E -->
</div>
</template>
<script>
export default {

  data: () => ({
    
  }),
  computed: {
  },
  methods: {
    
  },
};
</script>
