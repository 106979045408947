<template>
  <div>
    <VueFileAgent
      ref="vueFileAgent"
      :theme="'list'"
      :multiple="true"
      :deletable="true"
      :meta="true"
      :accept="'image/*'"
      :maxSize="'30MB'"
      :maxFiles="max"
      :helpText="'업로드할 사진을 선택해주세요.'"
      :errorText="{
        type: '업로드 파일 형식이 맞지 않습니다.',
        size: '최대 10Mb까지만 업로드 가능합니다.',
      }"
      @select="filesSelected($event)"
      @beforedelete="onBeforeDelete($event)"
      @delete="fileDeleted($event)"
      v-model="fileRecords"
    >
    </VueFileAgent>
    <!--
  <button :disabled="!fileRecordsForUpload.length" @click="uploadFiles()">
    <div class="text-center"><small>{{ fileRecordsForUpload.length }} 업로드</small></div>
  </button>-->
  </div>
</template>
<script>
import jwt from "../common/jwt";
export default {
  props: {
    files: { type: Array, required: false },
    max: { type: Number, required: false, default: 1 },
  },
  data: function () {
    return {
      fileRecords: [],
      uploadUrl: "//lotto7777.iwinv.net/api/upload/",
      uploadHeaders: {
        Authorization: jwt.getToken(),
        "X-Test-Header": "vue-file-agent",
      },
      fileRecordsForUpload: [], // maintain an upload queue
    };
  },

  mounted() {
    //페이지 시작하면은 자동 함수 실행
  },
  watch: {
    files() {
      this.fileRecords = [];
      this.loadGallery(this.files);
    },
    fileRecords() {
      this.$emit("files-event", this.fileRecords);

      this.fileRecords.forEach(function (item) {
        if (item) {
          //console.log(item.upload);
          //let upload = item.upload;
          //console.log(upload);
          console.log(JSON.parse(JSON.stringify(item)));
        }
      });
    },
  },

  methods: {
    loadGallery: function (files) {
      /*

        for (let i=0; i<files.length; i++) {

          console.log(files[i]);

        }
      
*/
      let self = this;

      //this.files.forEach(function(item, index, array){
      files.forEach(function (item) {
        self.fileRecords.push({
          //선택된 파일
          id: item.id,
          file: {},
          name: item.filename,

          //파일 선택시 urldata로보여지는 내용
          urlResized: "",

          //미리보기 이미지 업로드 되었을 경우
          url: "//lotto7777.iwinv.net" + item.url,
          lastModified: item.modified,
          color: "rgb(91, 85, 80)",

          size: item.size,
          type: item.mime,
          ext: item.ext,
          dimensions: { width: item.width, height: item.height },

          upload: { data: null, error: false }, //업로드 실행시 응답
        });
      });
    },

    uploadFiles: function () {
      // Using the default uploader. You may use another uploader instead.

      this.$refs.vueFileAgent.upload(
        this.uploadUrl,
        this.uploadHeaders,
        this.fileRecordsForUpload
      );
      this.fileRecordsForUpload = [];
    },
    //deleteUploadedFile: function (fileRecord) {
    deleteUploadedFile: function () {
      // Using the default uploader. You may use another uploader instead.
      /*
      저장할때 비로소 서버에서 삭제하도록 작업

      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
        //{'method':'delete'}
      );
      */
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);
      this.uploadFiles();
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        //if (confirm('정말로 지우시겠어요?')) {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        //}
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }

      console.log("delete:" + fileRecord.id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
