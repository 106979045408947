import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import goTo from "vuetify/es5/services/goto";

import store from "../store";

const beforeAuth = (isAuth) => (from, to, next) => {
  const isAuthenticated = store.getters["isAuthenticated"];
  if ((isAuthenticated && isAuth) || (!isAuthenticated && !isAuth)) {
    return next();
  } else {
    // 홈 화면으로 이동
    next("/login");
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/service",
    name: "Service",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "service" */ "../views/Service.vue"),
  },


  {
    path: "/analysis/auto",
    name: "AnalysisAuto",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "analysisauto" */ "../views/Analysis-Auto.vue"),
  },
  {
    path: "/analysis/fixed7",
    name: "AnalysisFixed7",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "analysisfixed7" */ "../views/Analysis-Fixed7.vue"),
  },
  {
    path: "/analysis/fixed8",
    name: "AnalysisFixed8",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "analysisfixed8" */ "../views/Analysis-Fixed8.vue"),
  },


  {
    path: "/lottowin/check",
    name: "LottoWin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "lottowincheck" */ "../views/Lottowin-Check.vue"),
  },
  {
    path: "/lottowin/result",
    name: "LottoWin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "lottowinresult" */ "../views/Lottowin-Result.vue"),
  },
  {
    path: "/lottowin/information",
    name: "LottoWin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "lottowininformation" */ "../views/Lottowin-Information.vue"),
  },
  {
    path: "/lottowin/video",
    name: "LottoWin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "lottowinvideo" */ "../views/Lottowin-Video.vue"),
  },



  {
    path: "/store/location",
    name: "StoreLocation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "storelocation" */ "../views/Store-Location.vue"),
  },
  {
    path: "/store/Top",
    name: "StoreTop",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "storetop" */ "../views/Store-Top.vue"),
  },
  {
    path: "/store/buyinfo",
    name: "StoreByInfo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "storebuyinfo" */ "../views/Store-Buy-Info.vue"),
  },
  {
    path: "/store/about",
    name: "StoreAbout",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "storeabout" */ "../views/Store-About.vue"),
  },
  {
    path: "/store/words",
    name: "StoreWords",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "storewords" */ "../views/Store-Words.vue"),
  },
  {
    path: "/store/history",
    name: "StoreHistory",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "storehistory" */ "../views/Store-History.vue"),
  },
  {
    path: "/bigdata/gold",
    name: "Bigdata",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "bigdata" */ "../views/Bigdata.vue"),
  },
  {
    path: "/customer",
    name: "Customer",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "customer" */ "../views/Customer.vue"),
  },
  {
    path: "/policy/terms",
    name: "terms",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "agreement" */ "../views/Policy-Terms.vue"),
  },
  {
    path: "/policy/privacy",
    name: "privacy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/Policy-Privacy.vue"),
  },
  {
    path: "/mypage",
    name: "Mypage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "mypage" */ "../views/Mypage.vue"),
    beforeEnter: beforeAuth(true),
  },
  {
    path: "/mynumbers",
    name: "MyNumbers",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "mynumbers" */ "../views/MyNumbers.vue"),
    beforeEnter: beforeAuth(true),
  },
  {
    path: "/orders",
    name: "Orders",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "orders" */ "../views/Orders.vue"),
    beforeEnter: beforeAuth(true),
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/join",
    name: "Join",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "join" */ "../views/Join.vue"),
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "ResetPassword" */ "../views/ResetPassword.vue"
      ),
  },
  {
    path: "/experts/:category?/:id?/:mode?",
    name: "Experts",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "experts" */ "../views/Experts.vue"),
  },

  {
    path: "/payment",
    name: "Payment",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "payment" */ "../views/Payment.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash.indexOf("tab_") > -1) {
      //alert(to.hash.indexOf('tab_'));
      return;
    }

    let scrollTo = 0;
    setTimeout(() => {
      if (to.hash) {
        scrollTo = to.hash;
      } else if (savedPosition) {
        scrollTo = savedPosition.y;
      }

      return goTo(scrollTo);
    }, 500); //페이지가 컴파일 되기까지 기다렸다가 지연 실행해야 오류 안남
  },
});

router.beforeEach(function (to, from, next) {
  console.log(to);
  console.log(from);
  //console.log('every single routing is pending');
  
  store.commit("UPDATE_SELECT_NUMBERS", []);
  store.commit("UPDATE_RESULT_NUMBERS", []);

  next()
});

export default router;
