<template>
 <v-sheet
    class="mx-auto mt-3"
  >
    <v-slide-group
          class="ml-2"
          mandatory
    >
      <v-slide-item
        v-for="item in items"
        :key="item.text"
      >
        <v-btn
          class="mx-1"
          active-class="primary white--text"
          depressed
          :to="item.url"
        >
          {{ item.text }}
        </v-btn>
      </v-slide-item>
    </v-slide-group>


    
  </v-sheet>
  
</template>
<script>
export default {

  data: () => ({
    
    items: [
      { text: "회원정보", icon: "mdi-menu-right", url: "/mypage" },
      { text: "로또보관함", icon: "mdi-menu-right", url: "/mynumbers" },
      { text: "충전/사용내역", icon: "mdi-menu-right", url: "/orders" },
      { text: "비밀번호 변경", icon: "mdi-menu-right", url: "/resetpassword" },
      { text: "로그아웃", icon: "mdi-menu-right", url:"logout"},
    ],


  }),
  computed: {
  },
  methods: {
    redirect() {
      console.log("redirect");
      console.log("isAuthenticated : " + this.isAuthenticated);
      if (!this.isAuthenticated) {
        /*
        this.$router.push({
          name: "Home",
        })
        */
        window.history.length > 2
          ? this.$router.go(-1)
          : this.$router.push("/");
      }
    },
    logout() {
      this.$store
        .dispatch("logout", {})
        .then(() => this.redirect())
        .catch(({ message }) => alert(message));
    },
  },
};
</script>
