<template>

 <v-sheet
    class="mx-auto mt-3"
  >
    <v-slide-group
          class="ml-2"
          mandatory
    >
      <v-slide-item
        v-for="item in items"
        :key="item.text"
      >
        <v-btn
          class="mx-1"
          active-class="primary white--text"
          depressed
          :to="item.url"
        >
          {{ item.text }}
        </v-btn>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>
<script>
export default {

  data: () => ({
    
    items: [

      { text: "20수 받기", icon: "mdi-menu-right" , url: "/analysis/auto"},
      { text: "고정7수 완전조합", icon: "mdi-menu-right" , url: "/analysis/fixed7"},
      { text: "고정8수+ 완전조합", icon: "mdi-menu-right", url: "/analysis/fixed8" },
      
      //{ text: "로또 용어정리", icon: "mdi-menu-right" , url: "/store/words"},
      //{ text: "로또 소개", icon: "mdi-menu-right" , url: "/store/about"},
      //{ text: "로또 역사", icon: "mdi-menu-right", url: "/store/history"},
      
    ],


  }),
  computed: {
  },
  methods: {
    
  },
};
</script>
