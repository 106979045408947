<template>

 <v-sheet
    class="mx-auto mt-3"
  >
    <v-slide-group
          class="ml-2"
          mandatory
    >
      <v-slide-item
        v-for="item in items"
        :key="item.text"
      >
        <v-btn
          class="mx-1"
          active-class="primary white--text"
          depressed
          :to="item.url"
        >
          {{ item.text }}
        </v-btn>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>
<script>
export default {

  data: () => ({
    
    items: [
      { text: "알고리즘 분석조합", icon: "mdi-menu-right", url: "/bigdata/gold" },
     // { text: "번호 출현 현황", icon: "mdi-menu-right", url: "" },
     // { text: "총합 통계", icon: "mdi-menu-right", url: "" },
      //{ text: "홀짝 통계", icon: "mdi-menu-right", url: "" },
      //{ text: "확률 및 조합", icon: "mdi-menu-right" , url: ""},
    ],

  }),
  computed: {
  },
  methods: {
    
  },
};
</script>
