import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios"; //axios 호출
import "./assets/css/style.css";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import VuePageTitle from "vue-page-title";

import VueFileAgent from "vue-file-agent";
import "vue-file-agent/dist/vue-file-agent.css";

Vue.use(VueFileAgent);

import VueMoment from "vue-moment";
import moment from "moment";
moment.locale("ko");
Vue.use(VueMoment, { moment });

//Component Registration
//폴더내에 있는 vue component 를 global 컴포넌트로 만들기 S
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
const requireComponent = require.context("./components/", false, /[\w-].vue$/);
requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);
  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\//, "").replace(/\.\w+$/, ""))
  );
  // Register component globally
  Vue.component(componentName, componentConfig.default || componentConfig);
});
//Component Registration
//폴더내에 있는 vue component 를 global 컴포넌트로 만들기 E

import http from "./http";
Vue.prototype.$http = http;

import common from "./assets/js/common";
Vue.use(common);

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    goUrl(url, params) {
      return this.$router.push(url, params);
    },
    goBack() {
      return this.$router.go(-1);
    },
    stripHTML(html) {
      return html.replace(/(<([^>]+)>)/gi, "");
    },
  },
});

import _ from 'lodash';
Object.defineProperty(Vue.prototype, '$_', { value: _ });

/*메타정보사용하려면,
import Meta from 'vue-meta'
Vue.use(Meta);
*/


//let init = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  
  created() {    
    const self = this;
//    let systemOption = null;


    let uuid = this.$nvl(window.localStorage.getItem('uuid'),self.$uuid());
    self.$localData('uuid',uuid);
    self.$store.dispatch("INIT_UUID", uuid);
    console.log('uuiid',uuid);

    
    self.$getServerInfo(uuid);
    self.$getWinnerResult();
    self.$getServiceResult();
/*
    this.$http
    .get(
      "https://lotto7777.iwinv.net/_api/v1/serverinfo.php" 
    )
    .then((res) => {
      console.log(res);

      if (res.status === 200) {

        //console.log('res',res.data);
          if(res.data.success){

            
            
            systemOption = res.data.data;
            self.$store.dispatch("INIT_SETTINGS", systemOption);
                    
            
            console.log('init',init);
            console.log('systemOption',systemOption);




          }
      
      } else {
        alert("실행중 실패했습니다.\n다시 이용해 주세요.");
      }

      if (this.firstLoad) this.firstLoad = false;
      init = false;
    });
*/



/*

     systemOption = {
      "service_mode": "dev",
      "service_banner_main": [
          {
              "src": "https://lotto7777.iwinv.net/platform/wp-content/uploads/2021/11/tmp_main_banner.b29edbd3.png",
              "url": "/analysis/auto",
              "code": ""
          }
      ],
      "service_banner_home_top": [
          {
              "src": false,
              "url": "",
              "code": ""
          }
      ],
      "service_banner_home_bottom": [
          {
              "src": false,
              "url": "",
              "code": ""
          }
      ],
      "service_banner_home_side_top": false,
      "service_banner_home_side_bottom": false,
      "service_banner_global_header": false,
      "service_banner_global_sub_top": false,
      "service_banner_global_sub_bottom": false,
      "service_banner_global_sub_side_top": false,
      "service_banner_global_sub_side_bottom": false,
      "service_footer": "<div class=\"caption mt-5 text--disabled text-center\" style=\"text-align: center;\">당사의 분석시스템은 누적패턴을 분석/필터링한 정보제공만을 목적으로 하며, 당첨확률 개선서비스가 아니므로 서비스 이용 과정에서 기대이익을 얻지 못하거나 발생한 손해 등에 대한 최종책임은 서비스 이용자 본인에게 있습니다.</div>\n<div></div>\n<div class=\"v-card__text gray--text text-center\">\n<div class=\"caption text-center mt-3 text--disabled\" style=\"text-align: center;\">로또7777777 사업자등록번호: 123-123-123456 통신판매업허가번호: 2020-서울서초-0309<br />\n서울시 송파구 송파대로20길 4, 헤브론복합빌딩 302(문정동) 전화: 1600-7777<br />\nCopyright © 2021 로또7777 All right reserved.</div>\n</div>\n",
      "service_faq": [
          {
              "title": "예상번호를 받으면 자동으로 로또구매가 되나요?",
              "content": "예상번호를 받으시고 복권구매를 하지 않으시는 경우 고액당첨이 되시더라도 당첨금 수령이 불가하니, 예상번호를 받으신후 복권구입은 직접 로또 판매점에서 구입하셔야 합니다. \r\n\r\n* 현재인터넷 사이트나 기타 수단을 통해 로또복권을 판매하거나구매대행하는 것은 불법입니다."
          },
          {
              "title": "문자(SMS, LMS)가 오지 않습니다.",
              "content": "최근 각 통신사의 스팸정책에 따라 문자가 수신되지 않을 수 있습니다. \r\n\r\n회원님들께서는 이용중인 통신사로 전화하셔서 스팸필터링 부가서비스를 해지하시면 정상적으로 문자수신이 가능하며 스팸필터링 부가서비스 해지를 원치 않으신 고객님들께서는 스팸필터링 허용번호등록에 고객센터 번호를 입력해주시기 바랍니다. \r\n\r\n그 밖에 휴대폰 자체에 SMS스팸차단 기능으로 고객센터 번호가 스팸번호로 등록이 되어있는지 확인해주시고, 후후/후스콜등 스팸차단 어플에서도 저희 고객센터 번호가 스팸등록되어있는지 확인부탁드립니다. \r\n\r\n*문자 수신요일에 휴대폰을 꺼두시거나 수신이 약한 지역에 계셨다면 문자수신이 안될수도 있으니, 문자를 받지 못한 경우 고객센터로 연락주시면 재발송 도움 드리겠습니다. \r\n문자로 전송되신 번호들은 홈페이지 상단메뉴의 [마이페이지] -> [문자발송내역] 페이지에서도 확인하실 수 있으니 이용에 참고하시기 바랍니다."
          },
          {
              "title": "조합 번호를 나눠서 SMS 전송을 받고 싶어요.",
              "content": "현재 수신요일을 나누어서 자동으로 추천 조합을 발송해 드릴수는 없습니다. \r\n\r\n만약 추천 조합을 나누어서 사용하시려면 수신요일 전에 홈페이지 접속 후 상단의 [빅데이터 추출번호] 를 통해 수동으로 사용하시면 해당 조합을 문자(MMS)로 보내드리고 있습니다. \r\n\r\n위 방법을 이용하시면 추천 조합을 나눠서 받아보실 수 있으니 참고하여 주시기 바랍니다. 감사합니다."
          },
          {
              "title": "무료 추천조합 5조합은 언제 제공되나요?",
              "content": "무료 추천 조합은 전체회원님 대상으로 매주 5조합이 무료 제공됩니다.\r\n\r\n이 무료 추천조합은 골드회원과는 달리 문자 전송은 이루어지지 않으며, 골드대박조합시스템과 다르게 기본필터링 시스템을 거친 조합들이 발송됩니다.\r\n확률 높은 골드대박조합을 받는것을 추천드립니다. \r\n\r\n무료 추천조합은 매주 화요일에서 목요일 5조합씩 순차적으로 발송되며, 마이로또를 통해 확인 하실 수 있습니다. 무료추천조합 발송시간: 화요일 [새벽1시 부터 5조합 발송] 로그인 후 상단메뉴 중 [마이페이지]페이지를 통해 조합받으신 번호를 확인가능하며, 실제 로또복권으로 구매해 주시면 되겠습니다."
          },
          {
              "title": "예상번호 문자 전송시간은 언제인가요?",
              "content": "문자 전송시간은 [오전 09:00 ~ 오후 13:00] 사이에 전송이 이루어 집니다. 문자 전송시간은 1등예측 시시스템 업데이트로 요일에 따라서 변경 될 수 있습니다. \r\n또한 문자로 전송되신 번호들은 홈페이지 [마이페이지]] 에서도 확인하실 수 있으니 이용에 참고하시기 바랍니다."
          }
      ]
    };
*/
    


  }
}).$mount("#app");

//서브타이틀 지정위한 코드
Vue.use(VuePageTitle, {
  // prefix: 'My App - ',
  suffix: "- 로또7777 ",
});
