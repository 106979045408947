<template>
  <v-list flat min-height="268" class="pl-3">
    <v-list-item-group color="primary" mandatory>
      <v-list-item v-for="(item, i) in items" :key="i" :to="item.url">
        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {

  data: () => ({
    
    items: [
      { text: "자유토론", icon: "mdi-menu-right", url: "/experts/free" },
      { text: "명인 도전방", icon: "mdi-menu-right", url: "/experts/challenge" },
      { text: "전문가 분석", icon: "mdi-menu-right", url: "/experts/expert" },
    ],


  }),
  computed: {
  },
  methods: {
    
  },
};
</script>
