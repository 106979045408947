<template>
  <div>
    <CommentWrite
      :post="post"
      @refresh-event="reloadComment"
      v-if="writer == 'top'"
    ></CommentWrite>

    <v-card class="mx-auto" elevation="0" color="grey lighten-2">
      <v-list two-line class="my-0 py-0">
        <template v-for="(item, index) in items">
          <v-list-item class="px-0 align-start" :key="item.index">
            <template v-slot:default="{}">
              <v-img
                style="background: #eee; border-radius: 40px"
                class="align-center text-center mr-2 mt-4"
                height="32"
                max-width="32"
              >
                <v-icon
                  v-if="!item.author_info.image"
                  class="text--disabled"
                  style="font-size: 22px"
                  >mdi-account</v-icon
                >
              </v-img>
              <v-list-item-content>
                <v-list-item-subtitle class="mt-0 mb-1"
                  ><strong class="mr-2 font-weight-bold">{{
                    item.author_info.nickname
                  }}</strong>
                  <small class="mr-2">
                    {{
                      $moment(item.date).format("YYYY-MM-DD hh:mm:ss")
                    }}</small
                  >
                  <v-spacer></v-spacer>
                </v-list-item-subtitle>

                <div>
                  <small>{{ item.content }}</small>
                </div>

                <!--
                <v-list-item-title
                  v-text="item.title"
                  class="font-weight-bold"
                ></v-list-item-title>
                  <v-list-item-subtitle
                    class="text--primary"
                    v-text="'a'"
                  ></v-list-item-subtitle>
-->
              </v-list-item-content>

              <v-list-item-action v-if="item.fields.image">
                <v-img
                  :aspect-ratio="1 / 1"
                  :src="'//lotto7777.iwinv.net' + item.fields.image.medium"
                  style="border-radius: 4px"
                  width="60"
                >
                </v-img>
              </v-list-item-action>

              <v-list-item-action>
                <v-menu offset-y v-if="$store.state.isAuthenticated">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list class="py-0">
                    <v-list-item>
                      <v-list-item-title @click="clickEdit(item.id)"
                        >수정하기</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title @click="clickDelete(item.id)"
                        >삭제하기</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-card
            v-if="item.fields.gallery"
            :key="index"
            class="d-flex justify-start mb-3"
            flat
            tile
          >
            <router-link
              icon
              target="_blank"
              v-for="(image, idx) in item.fields.gallery"
              :key="idx"
              :to="'//lotto7777.iwinv.net' + image.url"
            >
              <v-img
                :src="'//lotto7777.iwinv.net' + image.medium"
                :lazy-src="'//lotto7777.iwinv.net' + image.thumbnail"
                class="grey lighten-2 rounded mr-2"
                width="80"
                aspect-ratio="1"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </router-link>
          </v-card>

          <v-divider
            v-if="index < items.length - 1"
            :key="item.index"
          ></v-divider>
        </template>

        <div v-if="loading">
          <v-divider v-if="!firstLoad"></v-divider>
          <template cols="12" sm="12" md="12" lg="12" v-for="n in 5">
            <div :key="n">
              <v-skeleton-loader
                class="mx-auto pa-5 pb-3"
                type="paragraph"
              ></v-skeleton-loader>

              <v-divider v-if="n < 5"></v-divider>
            </div>
          </template>
        </div>

        <div v-if="loading === false && isCanNext">
          <v-divider class="mb-0"></v-divider>
          <v-btn block plain color="grey lighten-2 " @click="nextPage">
            <v-icon class="text--disabled">mdi-plus</v-icon>
            <span class="text--disabled">더 보기</span></v-btn
          >
        </div>
      </v-list>
    </v-card>

    <div
      v-if="loading === false && items.length < 1"
      class="text--disabled text-center my-5"
    >
      <div v-if="this.$route.query.q">
        <h3>"{{ this.$route.query.q }}"</h3>
        검색 결과가 없습니다.
      </div>
      <div v-else><small>등록된 댓글이 없습니다.</small></div>
    </div>

    <v-divider></v-divider>

    <CommentWrite
      :post="post"
      @refresh-event="reloadComment"
      v-if="writer == 'bottom'"
    ></CommentWrite>

    <v-dialog v-model="dialogDelete" max-width="250">
      <v-card>
        <v-card-title class="justify-center mb-4" style="font-size: 2rem">
          <div>{{ dialogDeleteInfo.emoji }}</div>
        </v-card-title>
        <v-card-subtitle class="text-center pb-2 font-weight-bold">
          <div>{{ dialogDeleteInfo.title }}</div>
        </v-card-subtitle>

        <v-card-text class="text-center">
          <div v-if="dialogDeleteInfo.firstLineText">
            {{ dialogDeleteInfo.firstLineText }}
          </div>
          <div v-if="dialogDeleteInfo.secondLineText">
            {{ dialogDeleteInfo.secondLineText }}
          </div>
          <div v-if="dialogDeleteInfo.thirdLineText">
            {{ dialogDeleteInfo.thirdLineText }}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="grey" text @click="dialogDelete = false"> 취소 </v-btn>

          <v-btn color="primary" text @click="orderDelete(dialogDeleteInfo.id)">
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogEdit"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      :max-width="$vuetify.breakpoint.mdAndDown ? '' : '640'"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-card-actions class="mr-0">
          <strong>댓글 수정</strong>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogEdit = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-responsive class="pa-3">
          <CommentWrite
            :post="post"
            :id="dialogEditInfo.id"
            :mode="'edit'"
            @refresh-event="reloadComment"
            button="bottom"
          ></CommentWrite>
        </v-responsive>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
//import http from "../http";
//import Like from "./Like";

export default {
  name: "classlistgrid",
  title: "classlistgrid",
  props: {
    post: { type: Number, required: true },

    count: { type: String, required: false, default: "5" },
    page: { type: String, required: false, default: "1" },
    skin: { type: String, required: false, default: "" },

    search: { type: String, required: false, default: "" },
    include: { type: String, required: false, default: "" },
    exclude: { type: String, required: false, default: "" },
    order: { type: String, required: false, default: "" },

    writer: { type: String, required: false, default: "top" },
  },
  components: {
    //Like,
  },
  data() {
    return {
      items: [],

      thispage: null,
      lastpage: null,
      total: null,

      loading: true,
      firstLoad: true,

      dialogDelete: false,
      dialogDeleteInfo: {
        timeout: 0,
        //emoji: "😆",
        title: "정말로 삭제하시겠어요?",
        //firstLineText: "도움에 감사드립니다",
        //secondLineText: "",
        // thirdLineText: "셋째줄",
      },

      dialogEdit: false,
      dialogEditInfo: {
        timeout: 0,
        //emoji: "😆",
        //title: "댓글 수정하기",
        //firstLineText: "도움에 감사드립니다",
        //secondLineText: "",
        // thirdLineText: "셋째줄",
      },
    };
  },
  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.getList();

    console.log(this.firstLoad);
  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        /* router path가 변경될 때마다 */

        this.getList();
      }
    },

    order() {
      console.log("watch order");

      //카테고리를 이동하면서 발생되는 이벤트는 초기 로딩이 완료 된 후에 작동
      if (this.firstLoad === false) {
        this.items = [];
        this.getList();
      }
    },
    category() {
      console.log("watch number");

      //카테고리를 이동하면서 발생되는 이벤트는 초기 로딩이 완료 된 후에 작동
      if (this.firstLoad === false) {
        this.items = [];
        this.getList();
      }
    },
  },
  computed: {
    // 계산된 getter
    isCanNext: function () {
      // `this` 는 vm 인스턴스를 가리킵니다.

      let isCanNext = this.thispage < this.lastpage;
      return isCanNext;
    },
  },
  methods: {
    reloadComment() {
      this.dialogEdit = false;

      this.items = [];
      this.getList();
    },

    clickEdit(thisId) {
      this.dialogEditInfo.id = thisId.toString();
      this.dialogEdit = true;
    },
    clickDelete(thisId) {
      this.dialogDelete = true;
      this.dialogDeleteInfo.id = thisId;
      //this.orderDelete(thisId);
    },
    orderDelete() {
      this.dialogDelete = false;

      let params = {
        method: "delete",
      };
      return new Promise((resolve, reject) => {
        this.$http
          .post("/api/v1/comment/" + this.dialogDeleteInfo.id, params)
          .then((response) => {
            console.log(response);
            if (response.data.success) {
              let alertDialogInfo = {
                timeout: 1000,
                //emoji: "😆",
                title: "게시글을 삭제했습니다.",
                //firstLineText: "도움에 감사드립니다",
                //secondLineText: "",
                // thirdLineText: "셋째줄",
              };
              this.$store.dispatch("openAlertDialog", alertDialogInfo);

              this.reloadComment();
            } else {
              let alertDialogInfo = {
                timeout: 1000,
                //emoji: "😆",
                title: "삭제할 권한이 없습니다.",
                //firstLineText: "도움에 감사드립니다",
                //secondLineText: "",
                // thirdLineText: "셋째줄",
              };
              this.$store.dispatch("openAlertDialog", alertDialogInfo);
            }

            resolve(response);
          })
          .catch((error) => {
            let alertDialogInfo = {
              timeout: 1000,
              //emoji: "😆",
              title: "네트워크 연결 오류입니다.",
              firstLineText: "데이터 연결을 확인해주세요.",
              //secondLineText: "",
              // thirdLineText: "셋째줄",
            };
            this.$store.dispatch("openAlertDialog", alertDialogInfo);

            reject(error);
          });

        this.dialogDeleteInfo.id = "";
      });
    },

    thisCategory(category) {
      // `this` 는 vm 인스턴스를 가리킵니다.
      if (category) {
        return category.slug;
      } else {
        return "a"; //카테고리 없음
      }
    },
    thisCategoryName(category) {
      // `this` 는 vm 인스턴스를 가리킵니다.
      if (category) {
        return category.name;
      } else {
        return ""; //카테고리 없음
      }
    },

    nextPage() {
      if (this.thispage >= this.lastpage) return false;

      let nextpage = this.thispage + 1;

      //console.log(nextpage);
      this.getList(nextpage);
    },

    getList(page) {
      if (!page) page = this.page;
      this.loading = true;

      this.$http
        .get(
          "/api/v1/comment/?page=" +
            page +
            "&count=" +
            30 +
            "&post=" +
            this.post +
            "&order=" +
            this.order +
            "&search=" +
            this.search +
            "&include=" +
            this.include +
            "&exclude=" +
            this.exclude +
            "&parent=" +
            ""
        )
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            if (this.firstLoad) {
              this.items = res.data.items;
            } else {
              this.items = this.items.concat(res.data.items);
            }

            this.thispage = res.data.thispage;
            this.lastpage = res.data.lastpage;
            this.total = res.data.total;
            this.uid = res.data.uid;
            this.roles = res.data.roles;

            //this.paging = res.data.paging;
            //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

            //this.classes = res.data;
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });

      /*
      this.$axios
        .get(
          "https://bodeumedu.iwinv.net/api/v1/?type=class&page=1&count=-1&category_name=" +
            this.category
        )
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            this.items = res.data.items;
            this.thispage = res.data.thispage;
            this.lastpage = res.data.lastpage;
            this.total = res.data.total;
            this.uid = res.data.uid;
            this.roles = res.data.roles;

            //this.paging = res.data.paging;
            //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

            //this.classes = res.data;
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });
*/
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "GongGothicMedium";
  font-size: 40px;
}

.bt_download {
  /* 시그니처 */
  background: linear-gradient(275.28deg, #ec4480 -25.76%, #ffb629 100%);
  border-radius: 6px;
  font-weight: bold;
}
.is_signature {
  border-radius: 6px;
  border: 3px solid #ffa229;
}

.class_item {
  border-radius: 6px;
  border: 3px solid #00000000;
}
.class_item_signature {
  border-radius: 6px;
  border: 3px solid #ffa229;
  border-style: inset;
}

.signature_tag {
  /* 시그니처 */
  left: 3px;
  border-radius: 0 !important;
}
</style>
