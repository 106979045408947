//import axios from "axios";
import http from "../http";
import jwt from "../common/jwt";
import store from "../store";

export async function refreshToken() {
  try {
    await http
      .post(
        "//lotto7777.iwinv.net/api/auth/refresh",
        {},
        {
          headers: {
            Authorization: "Bearer " + store.getters["getAccessToken"], //the token is a variable which holds the token
          },
        }
      )
      .then((response) => {
        console.log("response");
        console.log(response);
        //http.defaults.headers.common['Authorization'] = `Bearer ${response.data.data.jwt}`;

        //store.state.token.accessToken = response.data.data.jwt;
        //store.state.isAuthenticated = true;
        //jwt.saveToken(response.data.data.jwt);

        console.log("3");
        store.commit("UPDATE_TOKEN", response.data.data.jwt); //뮤테이션으로 트리거

        console.log("6");
      });

    return jwt.getToken();
  } catch (err) {
    return err;
  }
}
