<template>

  <v-card
  >
    <v-list dense>
      <v-subheader>로또정보</v-subheader>
      <v-list-item-group
        v-model="model"
        mandatory
        color="indigo"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
export default {

  data: () => ({
    

    items: [
      { text: "로또 소개", icon: "mdi-menu-right" },
      //{ text: "로또 용어정리", icon: "mdi-menu-right" },
      { text: "확률 및 조합", icon: "mdi-menu-right" },
      { text: "로또 구입요령", icon: "mdi-menu-right" },
     // { text: "로또 역사", icon: "mdi-menu-right" },
    ],

  }),
  computed: {
  },
  methods: {
    
  },
};
</script>
