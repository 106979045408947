<template>
  <v-row>
    <v-col class="text-center" cols="auto" style="font-size: 20px; color: #333;width:100%;">
      <v-chip
        v-for="(num, ii) in number"
        :key="ii"
        :class="
          $vuetify.breakpoint.smAndUp
            ? 'itemLottoNumber large'
            : 'itemLottoNumber'
        "
        :value="num"
        :color="
          num > 40
            ? '#b0d840'
            : num > 30
            ? '#aaa'
            : num > 20
            ? '#ff7272'
            : num > 10
            ? '#69c8f2'
            : num <= 10
            ? '#fbc400'
            : ''
        "
        :style="
          num > 40 && number.find((value) => value === num)
            ? 'color:#00000070'
            : num > 30 && number.find((value) => value === num)
            ? 'color:#00000070'
            : num > 20 && number.find((value) => value === num)
            ? 'color:#00000070'
            : num > 10 && number.find((value) => value === num)
            ? 'color:#00000070'
            : num <= 10 && number.find((value) => value === num)
            ? 'color:#00000070'
            : 'color:#00000070'
        "
      >
        {{ num }}
      </v-chip>
    </v-col>
    <!--<v-col class="text-right" style="font-size: 20px; color: #777"> </v-col>-->
  </v-row>
</template>
<script>
export default {
  name: "LottoNubmer",

  props: {
    number: { type: Array, required: false, default: null },
  },

  computed: {
    resultNumbers: {
      get() {
        return this.$store.state.resultNumbers;
      },
      set(payload) {
        this.$store.commit("UPDATE_RESULT_NUMBERS", payload);
        alert(payload);
      },
    },
  },

  /*
  data: () => ({
        resultNumbers: this.$store.state.resultNumbers
    
  }),
*/
  methods: {
    resetNumbers() {
      this.resultNumbers = [];
    },
  },
};
</script>
<style scoped>
.itemLottoNumber {
  display: inline-block;
  text-align: center;
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0;
  margin-right: 3px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
}
.itemLottoNumber.large {
  margin: 5px auto;
  height: 45px;
  width: 45px;
  margin-right: 10px;
  border-radius: 50%;
  font-size: 20px;
}
</style>
