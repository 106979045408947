<template>
 <v-sheet
    class="mx-auto mt-3"
  >
    <v-slide-group
          class="ml-2"
          mandatory
    >
      <v-slide-item
        v-for="item in items"
        :key="item.text"
      >
        <v-btn
          class="mx-1"
          active-class="primary white--text"
          depressed
          :to="item.url"
        >
          {{ item.text }}
        </v-btn>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
  
</template>
<script>
export default {

  data: () => ({
    
    items: [
      { text: "자유토론", icon: "mdi-menu-right", url: "/experts/free" },
      { text: "명인 도전방", icon: "mdi-menu-right", url: "/experts/challenge" },
      { text: "전문가 분석", icon: "mdi-menu-right", url: "/experts/expert" },
    ],


  }),
  computed: {
  },
  methods: {
    
  },
};
</script>
