<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="my-3"
        color="yellow black--text font-weight-bold"
        block
        large
        v-bind="attrs"
        v-on="on"
      >
        {{ button_text }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5"> {{ dialog_title }} </v-card-title>
      <v-card-text>
        {{ dialog_content }}

        <v-responsive class="pt-5"> 

<h2 class="text-center">200포인트</h2>

        </v-responsive>

        <v-responsive class="my-3">
          로또

          <a
            href="https://edu.bodeum.co.kr/policy/terms"
            class="text-decoration-none"
            target="_blank"
            >주의사항</a
          >
          과 서비스 이용관련
          <a
            href="https://edu.bodeum.co.kr/policy/terms"
            class="text-decoration-none"
            target="_blank"
            >필수 동의사항</a
          >을 반드시 확인해주세요.
        </v-responsive>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="mb-2">
          <v-btn color="grey darken-1" large text @click="dialog = false">
            {{
              !lesson_id ||
              !dog_id ||
              !ticket_id ||
              ticket_count_min > ticket_count
                ? "확인"
                : "취소"
            }}
          </v-btn>

          <!--
레슨id : {{ lesson_id }}<br>
반려견id: {{dog_id}}<br>
이용권id: {{ticket_id}}<br>
이용권count: {{ticket_count}}<br>
-->

          <v-btn
            v-if="lesson_id && dog_id && ticket_id"
            color="yellow darken-1 black--text ml-3"
            large
            :disabled="
              !lesson_id ||
              !dog_id ||
              !ticket_id ||
              ticket_count < ticket_count_min
            "
            @click="submitTicketAction"
          >
            포인트로 구매
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "lessonlistgrid",
  title: "lessonlistgrid",
  props: {
    lesson_id: { required: false, default: "" },

    title: { default: "" },

    category: { default: " " },
    tag: { default: " " },
    state: { type: String, required: false, default: "" },

    page: { type: String, required: false, default: "1" },
    count: { type: String, required: false, default: "-1" },
    skin: { type: String, required: false, default: "" },
    ratio: { type: Number, required: false, default: 16 / 9 },

    search: { type: String, required: false, default: "" },
    include: { type: String, required: false, default: "" },
    exclude: { type: String, required: false, default: "" },
    user: { type: String, required: false, default: "" },

    dog_count_min: { required: false, default: 1 },
    dog_count_max: { required: false, default: 1 },
    ticket_count_min: { required: false, default: 1 },

    button_text: { required: false, default: "조합받기" },
    dialog_title: { required: false, default: "행운번호 받기" },
    dialog_content: { required: false, default: "차감됩니다. 확인해주세요." },
  },
  components: {},
  data() {
    return {
      items: [],

      thispage: null,
      lastpage: null,
      total: null,

      loading: true,
      firstLoad: true,

      dog_id: null,
      ticket_id: null,
      ticket_count: null,

      dialog: null,
    };
  },
  mounted() {
    //페이지 시작하면은 자동 함수 실행
    //this.getList();

    console.log(this.firstLoad);
  },
  watch: {
    category() {
      console.log("watch number");

      //카테고리를 이동하면서 발생되는 이벤트는 초기 로딩이 완료 된 후에 작동
      if (this.firstLoad === false) {
        this.items = [];
        this.getList();
      }
    },
    state() {
      console.log("watch number");

      //카테고리를 이동하면서 발생되는 이벤트는 초기 로딩이 완료 된 후에 작동
      if (this.firstLoad === false) {
        this.items = [];
        this.getList();
      }
    },
  },
  computed: {
    userdata() {
      return this.$store.state.user.userdata;
    },

    // 계산된 getter
    isCanNext: function () {
      // `this` 는 vm 인스턴스를 가리킵니다.

      let isCanNext = this.thispage < this.lastpage;
      return isCanNext;
    },
  },
  methods: {
    submitTicketAction() {
      this.dialog = false;
    },

    selectDog(value) {
      this.dog_id = value;
      //this.dog = dog_item.id;
      console.log(value);
    },
    selectTicket(value) {
      this.ticket_id = value.ticket_id;
      this.ticket_count = value.total;
      //this.dog = dog_item.id;
      console.log("selectTicket");
      console.log(value);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
