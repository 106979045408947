<template>
  <div>
    <form @submit.prevent="formSubmit" method="post">
      <v-card
        :class="$vuetify.breakpoint.smAndUp ? 'mx-auto  mt-5' : 'mx-auto'"
        elevation="0"
        :outlined="$vuetify.breakpoint.smAndUp"
      >
        <v-toolbar flat color="transparent">
          <v-btn icon @click="goBack">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title
            v-if="mode === 'edit'"
            class="ml-0 pl-0 font-weight-bold"
            >글 수정</v-toolbar-title
          >
          <v-toolbar-title v-else class="ml-0 pl-0 font-weight-bold"
            >글 쓰기</v-toolbar-title
          >

          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            depressed
            v-if="$vuetify.breakpoint.mdAndUp"
            type="submit"
          >
            <span v-if="mode === 'edit'">수정</span>
            <span v-else>등록</span>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>
        <v-select
          v-model="category"
          :items="category_options"
          item-value="slug"
          item-text="label"
          label="게시판을 선택해주세요."
          solo
          flat
          hide-details
        ></v-select>

        <v-divider></v-divider>

        <v-skeleton-loader
          type="text"
          class="pa-5 pb-3 ma-0"
          v-if="loading"
        ></v-skeleton-loader>

        <v-text-field
          v-if="loading === false"
          v-model="title"
          solo
          flat
          label=""
          placeholder="제목"
          class="pa-0 ma-0"
          hide-details
        ></v-text-field>

        <v-divider></v-divider>
        <v-skeleton-loader
          type="paragraph"
          class="pa-5 pb-3 ma-0"
          v-if="loading"
        ></v-skeleton-loader>
        <v-textarea
          v-if="loading === false"
          v-model="content"
          solo
          flat
          label=""
          placeholder="내용을 입력하세요."
          hide-details
          auto-grow
        ></v-textarea>
        <v-divider></v-divider>
        <v-text-field
          v-model="tag"
          solo
          flat
          label=""
          placeholder="태그를 ,로 구분하여 입력해주세요."
          class="pa-0 ma-0"
          hide-details
        ></v-text-field>
        <v-divider></v-divider>
        <div :class="$vuetify.breakpoint.smAndUp ? 'pa-3' : 'mt-3'">
          <FileUploader :files="gallery" @files-event="getFileRecords" :max="5">
          </FileUploader>
        </div>
      </v-card>

      <div class="text-center mt-3 mb-10">
        <v-btn color="grey lighten-3 " depressed class="ma-2" @click="goBack">
          취소
        </v-btn>
        <v-btn color="primary" depressed class="ma-2" type="submit">
          <span v-if="mode === 'edit'">수정</span>
          <span v-else>등록</span>
        </v-btn>
      </div>
    </form>
  </div>
</template>
<script>
import http from "../http";
//import Like from "./Like";

export default {
  name: "classlistgrid",
  title: "classlistgrid",
  props: {
    id: { type: String, required: false, default: "" },
    mode: { type: String, required: false, default: "" },
  },
  components: {
    //Like,
  },
  data() {
    return {
      category: null,
      title: null,
      content: null,
      tag: null,
      images: null,

      gallery: null,

      fileRecords: null,

      category_options: [
        {
          id: 2,
          slug: "free",
          label: "자유 토론방",
        },
        {
          id: 3,
          slug: "challenge",
          label: "명인 도전방",
        },
        {
          id: 4,
          slug: "expert",
          label: "전문가 분석",
        },
      ],

      loading: true,
      firstLoad: true,

      autoUpload: false,
    };
  },
  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.getContent();

    if (this.$route.params.category) {
      this.category = this.$route.params.category;
    } else {
      //alert()
      this.category = this.category_options[0].slug;
    }

    console.log(this.firstLoad);
  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        /* router path가 변경될 때마다 */

        this.getContent();
      }
    },
  },
  computed: {
    // 계산된 getter
    isCanNext: function () {
      // `this` 는 vm 인스턴스를 가리킵니다.

      let isCanNext = this.thispage < this.lastpage;
      return isCanNext;
    },
    parentUrl: function () {
      if (this.from) {
        return this.from.path;
      } else {
        return `/experts/${this.item.fields.category.slug}/${this.item.id}`;
      }
    },
    uploadRecords: function () {
      let uploadRecords = [];
      if (this.fileRecords) {
        this.fileRecords.forEach(function (item) {
          if (item.id) {
            uploadRecords.push(item.id);
          } else {
            let _data = item.upload["data"];
            uploadRecords.push(_data);
          }
        });
      }

      return uploadRecords;
    },
  },
  methods: {
    getFileRecords(fileRecords) {
      //console.log('parent');
      //console.log(fileRecords);
      this.fileRecords = fileRecords;
    },

    async formSubmit() {
      if (this.uploadRecords.includes(null)) {
        let alertDialogInfo = {
          timeout: 1000,
          //emoji: "😆",
          title: "파일 업로드 중입니다.",
          secondLineText: "잠시 후 다시 시도해주세요.",
          // thirdLineText: "셋째줄",
        };
        this.$store.dispatch("openAlertDialog", alertDialogInfo);

        this.autoUpload = true;

        return;
      }

      let postObj = {
        title: this.title,
        status: "publish",
        fields: {
          content: this.content,
          category: this.category,
          tag: this.tag,
          gallery: this.uploadRecords,
        },
      };

      let postId = this.$route.params.id;
      if (postId === "new") {
        postId = "";
      }

      this.$store.dispatch("openAlertDialog", {
        timeout: 5000,
        //emoji: "😆",
        title: "저장 중...",
        //firstLineText: "도움에 감사드립니다",
        //secondLineText: "",
        // thirdLineText: "셋째줄",
        persistent: true,
      });

      http
        .post("/api/v1/social/" + postId, postObj)
        .then((response) => {
          this.$store.dispatch("closeAlertDialog");

          console.log(response);
          if (response.data.success) {
            let alertDialogInfo = {
              timeout: 1000,
              //emoji: "😆",
              title: "저장되었습니다.",
              //firstLineText: "도움에 감사드립니다",
              //secondLineText: "",
              // thirdLineText: "셋째줄",
              //persistent:true,
            };
            this.$store.dispatch("openAlertDialog", alertDialogInfo);

            this.$router.go(-1);

            //this.$router.push(`/experts/${this.category}`);
          } else {
            let alertDialogInfo = {
              timeout: 1000,
              //emoji: "😆",
              title: "글 작성 권한이 없습니다.",
              //firstLineText: "도움에 감사드립니다",
              //secondLineText: "",
              // thirdLineText: "셋째줄",
            };
            this.$store.dispatch("openAlertDialog", alertDialogInfo);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getContent() {
      if (this.$route.params.id === "new") {
        this.loading = false;
        this.firstLoad = false;
        this.title = "";
        this.content = "";
        return;
      }

      this.loading = true;
      this.$http
        .get("https://lotto7777.iwinv.net/api/v1/social/" + this.$route.params.id)
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            this.title = res.data.data.title;
            this.content = res.data.data.fields.content;

            if (res.data.data.fields.tag) {
              this.tag = res.data.data.fields.tag.join(",");
            }
            if (res.data.data.fields.gallery) {
              this.gallery = res.data.data.fields.gallery;
              //console.log(this.gallery);
            }

            //console.log(res.data.data.fields.tag);
            //console.log(this.tag);
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "GongGothicMedium";
  font-size: 40px;
}

.bt_download {
  /* 시그니처 */
  background: linear-gradient(275.28deg, #ec4480 -25.76%, #ffb629 100%);
  border-radius: 6px;
  font-weight: bold;
}
.is_signature {
  border-radius: 6px;
  border: 3px solid #ffa229;
}

.class_item {
  border-radius: 6px;
  border: 3px solid #00000000;
}
.class_item_signature {
  border-radius: 6px;
  border: 3px solid #ffa229;
  border-style: inset;
}

.signature_tag {
  /* 시그니처 */
  left: 3px;
  border-radius: 0 !important;
}
</style>
