<template>
 <v-sheet
    class="mx-auto mt-3"
  >
    <v-slide-group
          class="ml-2"
          mandatory
    >
      <v-slide-item
        v-for="item in items"
        :key="item.text"
      >
        <v-btn
          class="mx-1"
          active-class="primary white--text"
          depressed
          :to="item.url"
        >
          {{ item.text }}
        </v-btn>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>
<script>
export default {

  data: () => ({
    
    items: [

      { text: "당첨확인", icon: "mdi-menu-right", url: "/lottowin/check" },
      //{ text: "회차별 성적표", icon: "mdi-menu-right" , url: "/lottowin/check"},
      { text: "회차별 당첨결과", icon: "mdi-menu-right" , url: "/lottowin/result"},
      { text: "당첨금 지급안내", icon: "mdi-menu-right" , url: "/lottowin/information"},
      { text: "당첨영상 다시보기", icon: "mdi-menu-right" , url: "/lottowin/video"},


      
    ],


  }),
  computed: {
  },
  methods: {
    
  },
};
</script>
