//import axios from 'axios'
//import config from 'config';

export default {
  install(Vue) {
    // NVL
    Vue.prototype.$nvl = function (str, defaultStr) {
      if (typeof str == "undefined" || str == null || str == "")
        str = defaultStr;

      return str;
    };

    Vue.prototype.$sleep = (ms)=> new Promise (resolve => setTimeout (resolve, ms));

    // addCommas
    Vue.prototype.$addCommas = function (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // CheckMaxString
    Vue.prototype.$CheckMaxString = function (obj, maxNum) {
      var li_str_len = obj.length;
      var li_byte = 0;
      var li_len = 0;
      var ls_one_char = "";
      var ls_str2 = "";
      for (var j = 0; j < li_str_len; j++) {
        ls_one_char = obj.charAt(j);
        if (escape(ls_one_char).length > 4) {
          li_byte += 2;
        } else {
          li_byte++;
        }
        if (li_byte <= maxNum) {
          li_len = j + 1;
        }
      }
      if (li_byte > maxNum) {
        ls_str2 = obj.substr(0, li_len) + "...";
      } else {
        ls_str2 = obj;
      }
      return ls_str2;
    };

    // isEmpty
    Vue.prototype.$isEmpty = function (str) {
      if (typeof str == "undefined" || str == null || str == "") return true;
      else return false;
    };

    //이메일 정규식
    Vue.prototype.$emailValidation = function (data) {
      var regExp =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      if (regExp.test(data)) {
        //통과
        return true;
      } else {
        //올바르지 않음
        return false;
      }
    };

    //이메일 정규식
    Vue.prototype.$getCategorySlug = function (categoryObj) {
      // `this` 는 vm 인스턴스를 가리킵니다.
      if (categoryObj) {
        return categoryObj.slug;
      } else {
        return "a"; //카테고리 없음
      }
    };




    
    //numberToKorean
    Vue.prototype.$numberToKorean = function (number,floor) {


      var inputNumber  = number < 0 ? false : number;

      var inputFloor  = floor < 0 ? false : floor;
      if(inputFloor){ 
        inputNumber = Math.floor(inputNumber/inputFloor) * inputFloor;         
      }


      var unitWords    = ['', '만', '억', '조', '경'];
      var splitUnit    = 10000;
      var splitCount   = unitWords.length;
      var resultArray  = [];
      var resultString = '';
  
      for (var i = 0; i < splitCount; i++){
           var unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
          unitResult = Math.floor(unitResult);
          if (unitResult > 0){
              resultArray[i] = unitResult;
          }
      }
  
      for (var i2 = 0; i2 < resultArray.length; i2++){
          if(!resultArray[i2]) continue;
          resultString = String(resultArray[i2]) + unitWords[i2] + resultString;
      }
  
      return resultString;


    };


    // isJsonString
    Vue.prototype.$isJsonString = function (str) {
      try {
        var json = JSON.parse(str);
        return (typeof json === 'object');
      } catch (e) {
        return false;
      }
    };


  /***
   * 로컬 데이터 저장
   * 반환은 Array형태로 로 반환
   ***/
   Vue.prototype.$localData = function (key, value) {
    //var _functionName = localData.name;
    var _result;

    //return new Promise(function (resolve, reject) {

      if (this.$nvl(value)) {
        //console.log(`[${_functionName}] ${key} set`);

        if (typeof value === 'object') { //array라면, JSON으로 만들어 로컬스토리지 저장
          localStorage.setItem(key, JSON.stringify(value));
        } else {

          if (this.$isJsonString(value)) { //JSON이라면 그대로 로컬 스토리지 저장
            localStorage.setItem(key, value);
          } else { //그외 라면, 스트링이라고 생각하고,로컬 스토리지 저장
            localStorage.setItem(key, value);
          }
        }

      } else {
        //console.log(`[${_functionName}] ${key} get`);


      }

      var _data = localStorage.getItem(key);


      if (this.$isJsonString(_data)) {
        _result = JSON.parse(_data)
      } else {
        _result = _data
      }

      //resolve(_result)
      return _result
  };




    //numberToKorean
    Vue.prototype.$randomNumber = function (start,end) {
      return this.$_.random(this.$nvl(start,0), this.$nvl(end,9999999999));

    };
    Vue.prototype.$uuid = function (dash) {
      if(this.$nvl(dash,false)){
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
      }else{
            return ([1e7]+1e3+4e3+8e3+1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
          );
      }
    }

    //numberToKorean
    Vue.prototype.$getServerInfo = function (uuid) {
      const self = this;

      
      
    this.$http
    .get(
      "https://lotto7777.iwinv.net/_api/v1/serverinfo.php?uid="+ uuid
    )
    .then((res) => {
      console.log(res);

      if (res.status === 200) {

        //console.log('res',res.data);
          if(res.data.success){

            
            let systemOption = res.data.data;
            self.$store.dispatch("INIT_SETTINGS", systemOption);
                    
            
            console.log('systemOption',systemOption);




          }
      
      } else {
        alert("실행중 실패했습니다.\n다시 이용해 주세요.");
      }

    });



    };
    //numberToKorean
    Vue.prototype.$getWinnerResult = function () {
      const self = this;

      
      
      self.$http
    .get(
      "https://lotto7777.iwinv.net/lottoapp/getgameresult_drwNo.php"
    )
    .then((res) => {
      console.log(res);

      if (res.status === 200) {

        console.log('getWinnerResult',res.data);
        self.$store.dispatch("GET_WINNER_RESULT", res.data);

        /*
          if(res.data.success){




            
            let systemOption = res.data.data;
            self.$store.dispatch("INIT_SETTINGS", systemOption);
                    
            
            console.log('systemOption',systemOption);



          }
*/
      
      } else {
        alert("실행중 실패했습니다.\n다시 이용해 주세요.");
      }

    });



    };
    //numberToKorean
    Vue.prototype.$getServiceResult = function () {
      const self = this;

      
      
      self.$http
    .get(
      "https://lotto7777.iwinv.net/lottoapp/cron_result_check.php"
    )
    .then((res) => {
      console.log(res);

      if (res.status === 200) {

        console.log('getServiceResult',res.data);
        self.$store.dispatch("GET_SERVICE_RESULT", res.data);

        /*
          if(res.data.success){




            
            let systemOption = res.data.data;
            self.$store.dispatch("INIT_SETTINGS", systemOption);
                    
            
            console.log('systemOption',systemOption);



          }
*/
      
      } else {
        alert("실행중 실패했습니다.\n다시 이용해 주세요.");
      }

    });



    };









  },
};
