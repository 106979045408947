<template>
 <v-card
    class="mx-auto"
    flat
  >
    <v-list dense>
      <v-responsive
          style="padding-left:0;color:#333;font-size:17px;font-weight:bold;" v-html="title"></v-responsive>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
        style="margin-top:15px;"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          style="background-color:#eaf1fd;margin-bottom:8px;"
          :to="item.link"
        >
          <v-list-item-content>
            <v-list-item-title v-text="item.text" style="text-align:left;color:#39577d;font-weight:bold;"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
  export default {
  props: {
    title: String,
  },
    data: () => ({
      selectedItem: null,
      items: [
        { text: '로또 소개', icon: 'mdi-text-box-search-outline' ,link:'/store/about'},
        //{ text: '용어정리', icon: 'mdi-school-outline' },
        { text: '구입요령', icon: 'mdi-receipt'  ,link:'/store/buyinfo'},
        { text: '히스토리', icon: 'mdi-bookmark-outline'  ,link:'/store/history'},
      ],
    }),
  }
  
</script>
<style scoped>

</style>
