<template>
    <v-responsive style="font-size: 20px; color: #333">
      <v-chip
        v-for="(num, ii) in number"
        :key="ii"
        :class="
          ($vuetify.breakpoint.smAndUp && viewtype =='large')
            ? 'itemLottoNumber large'
            : 'itemLottoNumber'
        "
        :value="num"
        :color="
          num > 40
            ? '#b0d840'
            : num > 30
            ? '#aaa'
            : num > 20
            ? '#ff7272'
            : num > 10
            ? '#69c8f2'
            : num <= 10
            ? '#fbc400'
            : ''
        "
        :style="
          num > 40 && number.find((value) => value === num)
            ? 'color:#00000070'
            : num > 30 && number.find((value) => value === num)
            ? 'color:#00000070'
            : num > 20 && number.find((value) => value === num)
            ? 'color:#00000070'
            : num > 10 && number.find((value) => value === num)
            ? 'color:#00000070'
            : num <= 10 && number.find((value) => value === num)
            ? 'color:#00000070'
            : 'color:#00000070'
        "
      >
        {{ num }}
      </v-chip>

      <template v-if="bonus">
        <v-icon>mdi-plus</v-icon>
          <v-chip
            :class="
          ($vuetify.breakpoint.smAndUp && viewtype =='large')
                ? 'itemLottoNumber large'
                : 'itemLottoNumber'
            "
            :value="bonus"
            :color="
              bonus > 40
                ? '#b0d840'
                : bonus > 30
                ? '#aaa'
                : bonus > 20
                ? '#ff7272'
                : bonus > 10
                ? '#69c8f2'
                : bonus <= 10
                ? '#fbc400'
                : ''
            "
            :style="
              bonus > 40 
                ? 'color:#00000070'
                : bonus > 30 
                ? 'color:#00000070'
                : bonus > 20 
                ? 'color:#00000070'
                : bonus > 10 
                ? 'color:#00000070'
                : bonus <= 10 
                ? 'color:#00000070'
                : 'color:#00000070'
            "
          >
            {{ bonus }}
          </v-chip>
      </template>


    </v-responsive>
</template>
<script>
export default {
  name: "LottoNubmer",

  props: {
    number: { type: Array, required: false, default: null },
    bonus: { required: false, default: null },
    viewtype: { required: false, default: 'large' },
  },

  computed: {
    resultNumbers: {
      get() {
        return this.$store.state.resultNumbers;
      },
      set(payload) {
        this.$store.commit("UPDATE_RESULT_NUMBERS", payload);
        alert(payload);
      },
    },
  },

  /*
  data: () => ({
        resultNumbers: this.$store.state.resultNumbers
    
  }),
*/
  methods: {
    resetNumbers() {
      this.resultNumbers = [];
    },
  },
};
</script>
<style scoped>
.itemLottoNumber {
  display: inline-block;
  text-align: center;
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0;
  margin-left: 2px;
  margin-right: 2px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
}
.itemLottoNumber.large {
  margin: 5px auto;
  height: 45px;
  width: 45px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 50%;
  font-size: 20px;
}
</style>
