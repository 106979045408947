<template>
  <div>
    <v-card class="pa-5" elevation="0" outlined>
      <div class="mb-5 text-h5 font-weight-bold">
        고정 수 <span v-if="min">{{ min }} ~ </span>{{ max }}개를 선택해주세요.
      </div>

      <v-sheet
        class=""
        color="grey lighten-4 pa-5 mt-3 mb-5"
        style="font-size: 20px; font-weight: bold"
        rounded="lg"
      >
        <v-row>
          <v-col
            class="text-left"
            cols="auto"
            style="font-size: 20px; color: #333"
            >{{ selectedNumbers.join(", ") }}

            <v-btn
              v-if="selectedNumbers.join(',') != ''"
              icon
              color="grey lighten-1"
              @click="resetNumbers"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
            <span v-else class="text--disabled">선택한 수가 없습니다.</span>
          </v-col>
          <v-col class="text-right" style="font-size: 20px; color: #777"
            >{{ selectedNumbers.length }} / <span v-if="min">{{ min }} ~ </span>{{ max }}</v-col
          >
        </v-row>
      </v-sheet>

      <v-chip-group v-model="selectedNumbers" column multiple>
        <v-responsive
          v-for="i in 50"
          :key="i"
          :style="
            i > 45
              ? 'visibility:hidden;width:10%;'
              : 'width:10%; margin-bottom:5px;'
          "
        >
          <v-chip
            :value="i"
            :class="
              $vuetify.breakpoint.smAndUp
                ? 'itemLottoNumber large'
                : 'itemLottoNumber'
            "
            :outlined="!selectedNumbers.find((value) => value === i)"
            :color="
              i > 40
                ? '#b0d840'
                : i > 30
                ? '#aaa'
                : i > 20
                ? '#ff7272'
                : i > 10
                ? '#69c8f2'
                : i <= 10
                ? '#fbc400'
                : ''
            "
            :style="
              i > 40 && selectedNumbers.find((value) => value === i)
                ? 'color:#00000070'
                : i > 30 && selectedNumbers.find((value) => value === i)
                ? 'color:#00000070'
                : i > 20 && selectedNumbers.find((value) => value === i)
                ? 'color:#00000070'
                : i > 10 && selectedNumbers.find((value) => value === i)
                ? 'color:#00000070'
                : i <= 10 && selectedNumbers.find((value) => value === i)
                ? 'color:#00000070'
                : ''
            "
            @click.prevent="checkCount(i)"
          >
            {{ i }}
          </v-chip>
        </v-responsive>
      </v-chip-group>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "selectedNumbers",

  props: {
    min: { type: Number, required: false, default: 0 },
    max: { type: Number, required: false, default: 45 },
  },
  data: () => ({}),

  computed: {
    selectedNumbers: {
      get() {
        return this.$store.state.selectedNumbers;
      },
      set(payload) {
        this.$store.commit("UPDATE_SELECT_NUMBERS", payload);
      },
    },
  },
  methods: {
    setSubMenu(no) {
      this.selectedItem = no;
    },
    checkCount() {
      setTimeout(() => {
        //console.log(num);
        //console.log(this.max);
        //console.log(this.selectedNumbers);
        //console.log(this.selectedNumbers.length);
        if (this.selectedNumbers.length > this.max) {
          this.selectedNumbers.splice(-1, 5);
        }
      }, 10);
    },
    resetNumbers() {
      this.selectedNumbers = [];
    },
  },
};
</script>
<style scoped>
.itemLottoNumber {
  display: block;
  text-align: center;
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
}
.itemLottoNumber.large {
  margin: 5px auto;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  font-size: 20px;
}
</style>
