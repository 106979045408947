<template>
  <v-list flat min-height="268" class="pl-3">
    <v-list-item-group color="primary" mandatory>
      <v-list-item v-for="(item, i) in items" :key="i" :to="item.url">
        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {

  data: () => ({
    
    items: [
      { text: "알고리즘 분석조합", icon: "mdi-menu-right", url: "/bigdata/gold" },
      //{ text: "번호 출현 현황", icon: "mdi-menu-right", url: "" },
     // { text: "총합 통계", icon: "mdi-menu-right", url: "" },
      //{ text: "홀짝 통계", icon: "mdi-menu-right", url: "" },
      //{ text: "확률 및 조합", icon: "mdi-menu-right" , url: ""},
    ],

  }),
  computed: {
  },
  methods: {
    
  },
};
</script>
