<template>
  <div>
    <div v-if="loading">
      <v-row justify="space-between" no-gutters class="mx-auto py-3">
        <v-col align="left" justify="center">
          <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <div class="py-3 pb-10 text-body-1">
        <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        <v-skeleton-loader type="paragraph"></v-skeleton-loader>
      </div>
    </div>

    <div v-if="item && loading === false">
      <v-row justify="space-between" no-gutters class="mx-auto py-3">
        <v-col align="left" justify="center">
          <div class="font-weight-bold">
            <small
              class="primary--text"
              style="cursor: pointer"
              @click="goUrl(`/experts/${item.fields.category.slug}`)"
            >
              {{ item.fields.category.name
              }}<v-icon
                small
                class="primary--text"
                style="top: -1px; left: -3px"
                >mdi-chevron-right</v-icon
              >
            </small>
            <h3 class="text-subtitle-1 font-weight-bold">
              {{ item.title }}
            </h3>
            <div class="">
              <small class="mr-2 font-weight-bold text--disabled">{{
                item.author_info.nickname
              }}</small>
              <small class="mr-2 text--disabled">{{
                $moment(item.date).format("YYYY-MM-DD")
              }}</small>
              <small class="mr-2 text--disabled">조회 {{ item.view }}</small>
            </div>
          </div>
        </v-col>

        <v-col align="right" class="align-end">
          <v-menu offset-y  v-if="$store.state.isAuthenticated">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>





            <v-list class="py-0">
              <v-list-item>
                <v-list-item-title
                  @click="
                    goUrl(
                      `/experts/${item.fields.category.slug}/${item.id}/edit`
                    )
                  "
                  >수정하기</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="orderDelete"
                  >삭제하기</v-list-item-title
                >
              </v-list-item>
            </v-list>





          </v-menu>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-carousel
        v-if="gallery && loading === false"
        :show-arrows="gallery.length < 2 ? false : true"
        hide-delimiter-background
        delimiter-icon="mdi-circle-medium"
      >
        <v-carousel-item
          v-for="(item, i) in gallery"
          :key="i"
          :src="'//lotto7777.iwinv.net' + item.url"
        ></v-carousel-item>
      </v-carousel>

      <div v-html="item.fields.content" class="py-3 pb-10 text-body-1"></div>

      <div class="text-left" v-if="item.fields.tag">
        <v-chip
          class="ma-3 my-5 ml-0"
          label
          small
          v-for="(item, i) in item.fields.tag"
          :key="i"
        >
          <v-icon left small> mdi-label </v-icon>
          {{ item }}
        </v-chip>
      </div>
    </div>

    <v-responsive v-if="loading === false">
      <v-row justify="space-between" no-gutters>
        <h4 class="py-3">댓글 {{ item.comments }}</h4>
        <v-btn-toggle v-model="commentOrder" mandatory>
          <v-btn value="ASC" small plain> 등록순 </v-btn>
          <v-btn value="DESC" small plain> 최신순 </v-btn>
        </v-btn-toggle>
      </v-row>
      <v-divider></v-divider>

      <CommentList
        ref="commentlist"
        :post="item.id"
        :order="commentOrder"
        writer="top"
        class="mt-5"
      ></CommentList>
    </v-responsive>

    <v-row justify="space-between" no-gutters class="my-5 pa-0">
      <v-col align="left" justify="center">
        <v-btn
          color="grey lighten-3 "
          depressed
          @click="goUrl(`/experts/${item.fields.category.slug}`)"
        >
          목록
        </v-btn>
      </v-col>

      <v-col align="right" class="align-end" v-if="$store.state.isAuthenticated">
        <v-btn class="mr-2"
          color="grey lighten-3 "
          depressed
          @click="orderDelete"
        >
          삭제
        </v-btn>
        <v-btn
          color="grey lighten-3 "
          depressed
          @click="
            goUrl(`/experts/${item.fields.category.slug}/${item.id}/edit`)
          "
        >
          수정
        </v-btn>
      </v-col>




    </v-row>
  </div>
</template>
<script>
//import http from "../http";
//import Like from "./Like";

export default {
  name: "classlistgrid",
  title: "classlistgrid",
  props: {
    category: { type: String, required: false, default: " " },
    page: { type: String, required: false, default: "1" },
    count: { type: String, required: false, default: "-1" },
    skin: { type: String, required: false, default: "" },
    ratio: { type: Number, required: false, default: 16 / 9 },

    search: { type: String, required: false, default: "" },
    include: { type: String, required: false, default: "" },
    exclude: { type: String, required: false, default: "" },

    from: { type: Object, required: false, default: null },
  },
  components: {
    //Like,
  },
  data() {
    return {
      gallery: null,
      item: null,
      items: [],

      thispage: null,
      lastpage: null,
      total: null,

      loading: true,
      firstLoad: true,

      commentOrder: "DESC",
    };
  },
  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.getContent();

    console.log(this.firstLoad);
  },

  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        /* router path가 변경될 때마다 */

        this.getContent();
      }
    },
  },
  computed: {
    // 계산된 getter
    isCanNext: function () {
      // `this` 는 vm 인스턴스를 가리킵니다.

      let isCanNext = this.thispage < this.lastpage;
      return isCanNext;
    },
    parentUrl: function () {
      if (this.from) {
        return this.from.path;
      } else {
        return `/experts/${this.item.fields.category.slug}/${this.item.id}`;
      }
    },
  },
  methods: {
    openAlert() {
      let alertDialogInfo = {
        timeout: 0,
        //emoji: "😆",
        title: "성공적으로 등록되었어요!",
        //firstLineText: "도움에 감사드립니다",
        //secondLineText: "",
        // thirdLineText: "셋째줄",
      };
      this.$store.dispatch("openAlertDialog", alertDialogInfo);
    },
    orderDelete() {
      let alertDialogInfo = {
        timeout: 0,
        //emoji: "😆",
        title: "정말로 삭제하시겠어요?",
        //firstLineText: "도움에 감사드립니다",
        //secondLineText: "",
        // thirdLineText: "셋째줄",

        button1: "close", //commit function name
        button1Payload: {}, //commit function name
        button1Color: "",
        button1Class: "",

        button2: "deletePost", //commit function name
        button2Payload: {
          id: this.item.id,
          //redirect: this.from.path,
          redirect: `/experts/${this.item.fields.category.slug}`,
        }, //commit function name
        button2Color: "primary",
        button2Class: "",
      };
      this.$store.dispatch("openAlertDialog", alertDialogInfo);
    },

    getContent() {
      this.loading = true;
      this.$http
        .get("https://lotto7777.iwinv.net/api/v1/social/" + this.$route.params.id)
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            this.item = res.data.data;

            this.uid = res.data.uid;
            this.roles = res.data.roles;

            if (res.data.data.fields.gallery) {
              this.gallery = res.data.data.fields.gallery;
              //console.log(this.gallery);
            }

            //this.paging = res.data.paging;
            //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

            //this.classes = res.data;
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "GongGothicMedium";
  font-size: 40px;
}

.bt_download {
  /* 시그니처 */
  background: linear-gradient(275.28deg, #ec4480 -25.76%, #ffb629 100%);
  border-radius: 6px;
  font-weight: bold;
}
.is_signature {
  border-radius: 6px;
  border: 3px solid #ffa229;
}

.class_item {
  border-radius: 6px;
  border: 3px solid #00000000;
}
.class_item_signature {
  border-radius: 6px;
  border: 3px solid #ffa229;
  border-style: inset;
}

.signature_tag {
  /* 시그니처 */
  left: 3px;
  border-radius: 0 !important;
}
</style>
