var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-5",attrs:{"elevation":"0","outlined":""}},[_c('div',{staticClass:"mb-5 text-h5 font-weight-bold"},[_vm._v(" 고정 수 "),(_vm.min)?_c('span',[_vm._v(_vm._s(_vm.min)+" ~ ")]):_vm._e(),_vm._v(_vm._s(_vm.max)+"개를 선택해주세요. ")]),_c('v-sheet',{staticStyle:{"font-size":"20px","font-weight":"bold"},attrs:{"color":"grey lighten-4 pa-5 mt-3 mb-5","rounded":"lg"}},[_c('v-row',[_c('v-col',{staticClass:"text-left",staticStyle:{"font-size":"20px","color":"#333"},attrs:{"cols":"auto"}},[_vm._v(_vm._s(_vm.selectedNumbers.join(", "))+" "),(_vm.selectedNumbers.join(',') != '')?_c('v-btn',{attrs:{"icon":"","color":"grey lighten-1"},on:{"click":_vm.resetNumbers}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1):_c('span',{staticClass:"text--disabled"},[_vm._v("선택한 수가 없습니다.")])],1),_c('v-col',{staticClass:"text-right",staticStyle:{"font-size":"20px","color":"#777"}},[_vm._v(_vm._s(_vm.selectedNumbers.length)+" / "),(_vm.min)?_c('span',[_vm._v(_vm._s(_vm.min)+" ~ ")]):_vm._e(),_vm._v(_vm._s(_vm.max))])],1)],1),_c('v-chip-group',{attrs:{"column":"","multiple":""},model:{value:(_vm.selectedNumbers),callback:function ($$v) {_vm.selectedNumbers=$$v},expression:"selectedNumbers"}},_vm._l((50),function(i){return _c('v-responsive',{key:i,style:(i > 45
            ? 'visibility:hidden;width:10%;'
            : 'width:10%; margin-bottom:5px;')},[_c('v-chip',{class:_vm.$vuetify.breakpoint.smAndUp
              ? 'itemLottoNumber large'
              : 'itemLottoNumber',style:(i > 40 && _vm.selectedNumbers.find(function (value) { return value === i; })
              ? 'color:#00000070'
              : i > 30 && _vm.selectedNumbers.find(function (value) { return value === i; })
              ? 'color:#00000070'
              : i > 20 && _vm.selectedNumbers.find(function (value) { return value === i; })
              ? 'color:#00000070'
              : i > 10 && _vm.selectedNumbers.find(function (value) { return value === i; })
              ? 'color:#00000070'
              : i <= 10 && _vm.selectedNumbers.find(function (value) { return value === i; })
              ? 'color:#00000070'
              : ''),attrs:{"value":i,"outlined":!_vm.selectedNumbers.find(function (value) { return value === i; }),"color":i > 40
              ? '#b0d840'
              : i > 30
              ? '#aaa'
              : i > 20
              ? '#ff7272'
              : i > 10
              ? '#69c8f2'
              : i <= 10
              ? '#fbc400'
              : ''},on:{"click":function($event){$event.preventDefault();return _vm.checkCount(i)}}},[_vm._v(" "+_vm._s(i)+" ")])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }