import Vue from "vue";
import Vuex from "vuex";
import router from "../router";

Vue.use(Vuex);
import jwt from "../common/jwt";
import http from "../http";

export default new Vuex.Store({
  state: {
    uid: null,
    token: {
      accessToken: jwt.getToken(),
    }, // 토큰정보
    isAuthenticated: !!jwt.getToken(),

    selectedNumbers: [],
    resultNumbers: [],

    sb: {
      act: false,
      msg: "",
      color: "error",
    },

    alertDialogToggle: false,
    alertDialogInfo: null,
    uuid : '',
    systemOption : null,
    winner_result:null,
    service_result:null,
  },
  getters: {
    //state에 저장된 리스트 전달
    getAccessToken: function (state) {
      return state.token.accessToken;
    },
    isAuthenticated: function (state) {
      return state.isAuthenticated;
    },
  },
  mutations: {
    //state에 저장
    logout: function (state, payload = {}) {
      console.log(payload);
      state.token.accessToken = "";
      state.isAuthenticated = false;
      jwt.destroyToken();
    },
    login: function (state, payload = {}) {
      state.token.accessToken = payload.accessToken;
      state.isAuthenticated = true;
      jwt.saveToken(payload.accessToken);
    },

    UPDATE_UID: function (state, payload = {}) {
      state.uid = payload.uid;
    },

    UPDATE_TOKEN: function (state, payload = {}) {
      console.log("4");

      state.token.accessToken = payload;
      state.isAuthenticated = true;
      jwt.saveToken(payload);
      console.log("5");
    },

    UPDATE_SELECT_NUMBERS: function (state, payload = {}) {
      state.selectedNumbers = payload;
    },
    UPDATE_RESULT_NUMBERS: function (state, payload = {}) {
      state.resultNumbers = payload;
    },

    pop(state, d) {
      state.sb.msg = d.msg;
      state.sb.color = d.color;
      state.sb.act = false;
      if (d.act === undefined) state.sb.act = true;
    },

    openAlertDialog(state, payload) {
      state.alertDialogInfo = payload;
      state.alertDialogToggle = true;
    },
    closeAlertDialog(state) {
      state.alertDialogInfo = null;
      state.alertDialogToggle = false;
    },
    
    UPDATE_SETTINGS: function (state,payload) {

      //console.log('UPDATE_SETTINGS',payload); 
      state.systemOption = payload; //systemoption

    },

    UPDATE_UUID: function (state,payload) {

      //console.log('UPDATE_UUID',payload); 
      state.uuid = payload; //systemoption

    },

    UPDATE_WINNER_RESULT: function (state,payload) {

      // console.log('UPDATE_WINNER_RESULT',payload); 
       state.winner_result = payload; //systemoption
 
     },
     UPDATE_SERVICE_RESULT: function (state,payload) {
 
      // console.log('UPDATE_WINNER_RESULT',payload); 
       state.service_result = payload; //systemoption
 
     },
    
  },
  actions: {
    GET_SERVICE_RESULT: async function (context, payload) {
      //console.log('INIT_SETTINGS',payload);
      
      context.commit("UPDATE_SERVICE_RESULT", payload);

    },
    GET_WINNER_RESULT: async function (context, payload) {
      //console.log('INIT_SETTINGS',payload);
      
      context.commit("UPDATE_WINNER_RESULT", payload);

    },
    INIT_UUID: async function (context, payload) {
      //console.log('INIT_SETTINGS',payload);
      
      context.commit("UPDATE_UUID", payload);

    },
    INIT_SETTINGS: async function (context, payload) {
      //console.log('INIT_SETTINGS',payload);
      
      context.commit("UPDATE_SETTINGS", payload);

    },
    logout: function (context, payload) {
      return new Promise((resolve) => {
        /*
        setTimeout(function () {
          context.commit("logout", payload)
          resolve({})
        }, 1000)*/

        context.commit("logout", payload); //뮤테이션으로 트리거
        resolve({});
      });
    },

    login: function (context, payload) {
      let params = {
        username: payload.username,
        password: payload.password,
      };
      return new Promise((resolve, reject) => {
        http
          .post("//lotto7777.iwinv.net/api/auth", params)
          .then((response) => {
            const { data } = response.data;
            context.commit("login", {
              accessToken: data.jwt,
            });

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    refreshToken: function (context, payload) {
      let params = {
        username: payload.username,
        password: payload.password,
      };
      return new Promise((resolve, reject) => {
        http
          .post("//lotto7777.iwinv.net/api/auth/refresh", params)
          .then((response) => {
            const { data } = response.data;
            context.commit("login", {
              accessToken: data.jwt,
            });

            resolve(response);
          })
          .catch((error) => {
            context.commit("logout", payload); //뮤테이션으로 트리거

            reject(error);
          });
      });
    },

    UPDATE_UID: function (context, payload) {
      return new Promise((resolve) => {
        context.commit("UPDATE_UID", payload); //뮤테이션으로 트리거
        resolve({});
      });
    },

    UPDATE_SELECT_NUMBERS: function (context, payload) {
      return new Promise((resolve) => {
        context.commit("UPDATE_SELECT_NUMBERS", payload); //뮤테이션으로 트리거
        resolve({});
      });
    },
    UPDATE_RESULT_NUMBERS: function (context, payload) {
      return new Promise((resolve) => {
        context.commit("UPDATE_RESULT_NUMBERS", payload); //뮤테이션으로 트리거
        resolve({});
      });
    },

    // Alert Dialog
    openAlertDialog({ commit }, alertDialogInfo) {
      commit("openAlertDialog", alertDialogInfo);
    },
    closeAlertDialog({ commit }) {
      commit("closeAlertDialog");
    },

    deletePost: function (context, payload) {
      let params = {
        method: "delete",
      };
      return new Promise((resolve, reject) => {
        http
          .post("/api/v1/social/" + payload.id, params)
          .then((response) => {
            console.log(response);
            if (response.data.success) {
              let alertDialogInfo = {
                timeout: 1000,
                //emoji: "😆",
                title: "게시글을 삭제했습니다.",
                //firstLineText: "도움에 감사드립니다",
                //secondLineText: "",
                // thirdLineText: "셋째줄",
              };
              context.dispatch("openAlertDialog", alertDialogInfo);

              if (payload.redirect) {
                router.push(payload.redirect);
              } else {
                router.go(-1);
              }
            } else {
              let alertDialogInfo = {
                timeout: 1000,
                //emoji: "😆",
                title: "삭제할 권한이 없습니다.",
                //firstLineText: "도움에 감사드립니다",
                //secondLineText: "",
                // thirdLineText: "셋째줄",
              };
              context.dispatch("openAlertDialog", alertDialogInfo);
            }

            resolve(response);
          })
          .catch((error) => {
            let alertDialogInfo = {
              timeout: 1000,
              //emoji: "😆",
              title: "네트워크 연결 오류입니다.",
              firstLineText: "데이터 연결을 확인해주세요.",
              //secondLineText: "",
              // thirdLineText: "셋째줄",
            };
            context.dispatch("openAlertDialog", alertDialogInfo);

            reject(error);
          });
      });
    },
  },
  modules: {},
});
