<template>
  <div>
    <v-card class="pa-5 mb-10" elevation="0" outlined>
      <v-row>
        <v-col class="text-left text-h5 font-weight-bold" cols="auto">
          조합 결과 보기
        </v-col>
        <v-col class="text-right" style="font-size: 20px; color: #777">
          {{ title }} {{ resultNumbers.length }} 수</v-col
        >
      </v-row>
      <v-sheet
        v-for="(number, i) in resultNumbers"
        :key="i"
        class=""
        color="grey lighten-4 pa-5 mt-3"
        style="font-size: 20px; font-weight: bold"
        rounded="lg"
      >
        <LottoNumber :number="number"></LottoNumber>
      </v-sheet>

      <p class="text--disabled mt-5 mb-0">
        <small>
          당사의 분석시스템은 누적패턴을 분석/필터링한 정보제공만을 목적으로
          하며, 당첨확률 개선서비스가 아니므로 서비스 이용 과정에서 기대이익을
          얻지 못하거나 발생한 손해 등에 대한 최종책임은 서비스 이용자 본인에게
          있습니다.
        </small>
      </p>
    </v-card>
  </div>
</template>
<script>
//import LottoNumber from "../components/LottoNumber";

export default {
  name: "LottoResult",

  components: {
    //HelloWorld,
    //LottoNumber,
  },

  props: {
    title: { type: String, required: false, default: "총 " },
    //resultNumbers: { type: Array, required: false, default: null },
  },

  computed: {
    resultNumbers: {
      get() {
        return this.$store.state.resultNumbers;
      },
      set(payload) {
        this.$store.commit("UPDATE_RESULT_NUMBERS", payload);
        alert(payload);
      },
    },
    /*
    resultNumbers: {
      get() {
        return this.$store.state.resultNumbers;
      },
      set(payload) {
        this.$store.commit("UPDATE_RESULT_NUMBERS", payload);
      },
    },
    */
  },

  /*
  data: () => ({
        resultNumbers: this.$store.state.resultNumbers
    
  }),
*/
  methods: {
    resetNumbers() {
      this.resultNumbers = [];
    },
  },
};
</script>
<style scoped>
.itemLottoNumber {
  display: inline-block;
  text-align: center;
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0;
  margin-right: 3px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
}
.itemLottoNumber.large {
  margin: 5px auto;
  height: 45px;
  width: 45px;
  margin-right: 10px;
  border-radius: 50%;
  font-size: 20px;
}
</style>
