<template>
  <v-dialog
    v-model="dialog"
    max-width="250"
    @click:outside="onClickOutside"
    :persistent="persistent"
  >
    <v-card>
      <v-card-title class="justify-center mb-4" style="font-size: 2rem">
        <div>{{ emoji }}</div>
      </v-card-title>
      <v-card-subtitle class="text-center pb-2 font-weight-bold">
        <div>{{ title }}</div>
      </v-card-subtitle>

      <v-card-text class="text-center">
        <div v-if="firstLineText">{{ firstLineText }}</div>
        <div v-if="secondLineText">{{ secondLineText }}</div>
        <div v-if="thirdLineText">{{ thirdLineText }}</div>
      </v-card-text>

      <v-card-actions v-if="button1 || button2">
        <v-spacer></v-spacer>

        <v-btn
          v-if="button1"
          :color="button1Color"
          text
          @click="onClickButton(button1, button1Payload)"
        >
          취소
        </v-btn>

        <v-btn
          v-if="button2"
          :color="button2Color"
          text
          @click="onClickButton(button2, button2Payload)"
        >
          확인
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  // props: ["title", "firstLineText", "secondLineText", "ThirdLineText", "btnText", "dialog"],
  props: {
    dialog: Boolean,
    timeout: {
      type: Number,
      default: 1000,
    },

    emoji: String,
    title: String,
    firstLineText: String,
    secondLineText: String,
    thirdLineText: String,

    button1: String, //commit function name
    button1Payload: Object, //any type
    button1Color: String,
    button1Class: String,

    button2: String, //commit function name
    button2Payload: Object, //any type
    button2Color: String,
    button2Class: String,
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.timeout) {
      setTimeout(() => {
        this.$store.dispatch("closeAlertDialog");
      }, this.timeout);
    }
  },
  // destroyed() {
  //   console.log("destoyed")
  // },
  methods: {
    onClickOutside() {
      if (!this.persistent) {
        this.$store.dispatch("closeAlertDialog");
      }
    },
    onClickButton(mutation, payload) {
      if (mutation != "close") {
        //this.$store.commit(mutation, payload);

        this.$store.dispatch(mutation, payload);
      }
      this.onClickOutside();
    },
  },
};
</script>

<style></style>
