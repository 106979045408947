<template>
  <div>
    <v-card class="mx-auto" elevation="0" outlined color="grey lighten-2">
      <v-list two-line class="my-0 py-0">
        <template v-for="(item, index) in items">
          <v-list-item
            class="pr-1"
            :key="item.index"
            :to="
              '/experts/' + thisCategory(item.fields.category) + '/' + item.id
            "
          >
            <template v-slot:default="{}">
              <v-list-item-content>
                <div class="font-weight-bold">
                  <small class="mr-1 text--disabled" v-if="!category">
                    [{{ thisCategoryName(item.fields.category) }}]</small
                  >

                  <small class="text--disabled" v-if="item.parent">
                    <v-icon small>mdi-subdirectory-arrow-right</v-icon
                    >{{ item.parent }}</small
                  >
                  {{ item.title }}
                </div>

                <!--
                <v-list-item-title
                  v-text="item.title"
                  class="font-weight-bold"
                ></v-list-item-title>
                  <v-list-item-subtitle
                    class="text--primary"
                    v-text="'a'"
                  ></v-list-item-subtitle>
-->
                <v-list-item-subtitle class="mt-1"
                  ><small class="mr-2 font-weight-bold">{{
                    item.author_info.nickname
                  }}</small>
                  <small class="mr-2">
                    {{ $moment(item.date).format("YYYY-MM-DD") }}</small
                  >
                  <small class="mr-2">조회 {{ item.view }}</small>

                  <small class="mr-2" v-if="$vuetify.breakpoint.mdAndUp"
                    >댓글 {{ item.comments }}</small
                  >
                  <v-spacer></v-spacer>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action v-if="item.fields.image">
                <v-img
                  :aspect-ratio="1 / 1"
                  :src="'//lotto7777.iwinv.net' + item.fields.image.medium"
                  style="border-radius: 4px"
                  width="60"
                >
                </v-img>
              </v-list-item-action>

              <v-img
                v-if="$vuetify.breakpoint.smAndDown"
                style="background: #eee"
                class="align-center ml-2 rounded"
                height="60"
                max-width="30"
                ><div class="text--disabled text-center">
                  <small style="font-size: 11px; font-weight: bold"
                    >댓글<br />{{ item.comments }}</small
                  >
                </div>
              </v-img>

              <!--
                <v-list-item-action>
                  <v-list-item-action-text
                    v-text="'c'"
                  ></v-list-item-action-text>
                  <v-icon v-if="!active" color="grey lighten-1">
                    mdi-star-outline
                  </v-icon>

                  <v-icon v-else color="yellow darken-3"> mdi-star </v-icon>
                </v-list-item-action>
-->
            </template>
          </v-list-item>

          <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
        </template>

        <div v-if="loading">
          <template cols="12" sm="12" md="12" lg="12" v-for="n in 5">
            <div :key="n">
              <v-skeleton-loader
                class="mx-auto pa-5 pb-3"
                type="paragraph"
              ></v-skeleton-loader>

              <v-divider v-if="n < 5"></v-divider>
            </div>
          </template>
        </div>

        <div v-if="loading === false && isCanNext">
          <v-divider class="mb-0"></v-divider>
          <v-btn block plain color="grey lighten-2 " @click="nextPage">
            <v-icon class="text--disabled">mdi-plus</v-icon>
            <span class="text--disabled">더 보기</span></v-btn
          >
        </div>
      </v-list>
    </v-card>

    <div
      v-if="loading === false && items.length < 1"
      class="text--disabled text-center my-5"
    >
      <div v-if="this.$route.query.q">
        <h3>"{{ this.$route.query.q }}"</h3>
        검색 결과가 없습니다.
      </div>
      <div v-else>결과가 없습니다.</div>
    </div>
  </div>
</template>
<script>
//import http from "../http";
//import Like from "./Like";

export default {
  name: "classlistgrid",
  title: "classlistgrid",
  props: {
    category: { type: String, required: false, default: "" },
    page: { type: String, required: false, default: "1" },
    count: { type: String, required: false, default: "-1" },
    skin: { type: String, required: false, default: "" },
    ratio: { type: Number, required: false, default: 16 / 9 },

    search: { type: String, required: false, default: "" },
    include: { type: String, required: false, default: "" },
    exclude: { type: String, required: false, default: "" },
  },
  components: {
    //Like,
  },
  data() {
    return {
      items: [],

      thispage: null,
      lastpage: null,
      total: null,

      loading: true,
      firstLoad: true,
    };
  },
  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.getList();

    console.log(this.firstLoad);
  },
  watch: {
    category() {
      console.log("watch number");

      //카테고리를 이동하면서 발생되는 이벤트는 초기 로딩이 완료 된 후에 작동
      if (this.firstLoad === false) {
        this.items = [];
        this.getList();
      }
    },
  },
  computed: {
    // 계산된 getter
    isCanNext: function () {
      // `this` 는 vm 인스턴스를 가리킵니다.

      let isCanNext = this.thispage < this.lastpage;
      return isCanNext;
    },
  },
  methods: {
    thisCategory(category) {
      // `this` 는 vm 인스턴스를 가리킵니다.
      if (category) {
        return category.slug;
      } else {
        return "a"; //카테고리 없음
      }
    },
    thisCategoryName(category) {
      // `this` 는 vm 인스턴스를 가리킵니다.
      if (category) {
        return category.name;
      } else {
        return ""; //카테고리 없음
      }
    },

    nextPage() {
      if (this.thispage >= this.lastpage) return false;

      let nextpage = this.thispage + 1;

      //console.log(nextpage);
      this.getList(nextpage);
    },

    getList(page) {
      if (!page) page = this.page;
      this.loading = true;

      this.$http
        .get(
          "//lotto7777.iwinv.net/api/v1/social/?page=" +
            page +
            "&count=" +
            this.count +
            "&category_name=" +
            this.category +
            "&search=" +
            this.search +
            "&include=" +
            this.include +
            "&exclude=" +
            this.exclude +
            "&parent=" +
            ""
        )
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            if (this.firstLoad) {
              this.items = res.data.items;
            } else {
              this.items = this.items.concat(res.data.items);
            }

            this.thispage = res.data.thispage;
            this.lastpage = res.data.lastpage;
            this.total = res.data.total;
            this.uid = res.data.uid;
            this.roles = res.data.roles;

            //this.paging = res.data.paging;
            //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

            //this.classes = res.data;
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });

      /*
      this.$axios
        .get(
          "https://bodeumedu.iwinv.net/api/v1/?type=class&page=1&count=-1&category_name=" +
            this.category
        )
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            this.items = res.data.items;
            this.thispage = res.data.thispage;
            this.lastpage = res.data.lastpage;
            this.total = res.data.total;
            this.uid = res.data.uid;
            this.roles = res.data.roles;

            //this.paging = res.data.paging;
            //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

            //this.classes = res.data;
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });
*/
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "GongGothicMedium";
  font-size: 40px;
}

.bt_download {
  /* 시그니처 */
  background: linear-gradient(275.28deg, #ec4480 -25.76%, #ffb629 100%);
  border-radius: 6px;
  font-weight: bold;
}
.is_signature {
  border-radius: 6px;
  border: 3px solid #ffa229;
}

.class_item {
  border-radius: 6px;
  border: 3px solid #00000000;
}
.class_item_signature {
  border-radius: 6px;
  border: 3px solid #ffa229;
  border-style: inset;
}

.signature_tag {
  /* 시그니처 */
  left: 3px;
  border-radius: 0 !important;
}
</style>
