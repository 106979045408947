<template>
  <v-list flat min-height="268" class="pl-3">
    <v-list-item-group color="primary" mandatory>
      <v-list-item v-for="(item, i) in items" :key="i" :to="item.url =='logout' ? '' : item.url" @click="item.url =='logout' ? logout() : ''" >
        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {

  data: () => ({
    
    items: [
      { text: "회원 정보", icon: "mdi-menu-right", url: "/mypage" },
      { text: "로또 보관함", icon: "mdi-menu-right", url: "/mynumbers" },
      { text: "충전/사용내역", icon: "mdi-menu-right", url: "/orders" },
      { text: "비밀번호 변경", icon: "mdi-menu-right", url: "/resetpassword" },
      { text: "로그아웃", icon: "mdi-menu-right", url:"logout"},
    ],


  }),
  computed: {
  },
  methods: {
    redirect() {
      console.log("redirect");
      console.log("isAuthenticated : " + this.isAuthenticated);
      if (!this.isAuthenticated) {
        /*
        this.$router.push({
          name: "Home",
        })
        */
        window.history.length > 2
          ? this.$router.go(-1)
          : this.$router.push("/");
      }
    },
    logout() {
      this.$store
        .dispatch("logout", {})
        .then(() => this.redirect())
        .catch(({ message }) => alert(message));
    },
  },
};
</script>
