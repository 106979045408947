<template>
  <v-list flat min-height="268" class="pl-3">
    <v-list-item-group color="primary" mandatory>
      <v-list-item v-for="(item, i) in items" :key="i" :to="item.url">
        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {

  data: () => ({
    
    items: [
      //{ text: "서비스 안내", icon: "mdi-menu-right", url: "/service" },
      


      //{ text: "고객지원", icon: "mdi-menu-right", url: "/customer" },

      
      { text: "이용약관", icon: "mdi-menu-right", url: "/policy/terms" },
      {
        text: "개인정보취급방침",
        icon: "mdi-menu-right",
        url: "/policy/privacy",
      },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],


  }),
  computed: {
  },
  methods: {
    
  },
};
</script>
