<template>
  <v-list flat min-height="268" class="pl-3">
    <v-list-item-group color="primary" mandatory>
      <v-list-item v-for="(item, i) in items" :key="i" :to="item.url">
        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {

  data: () => ({
    
    items: [

      { text: "1,2등 최다 판매점", icon: "mdi-menu-right" , url: "/store/top"},
      { text: "로또 구입요령", icon: "mdi-menu-right", url: "/store/buyinfo" },
      { text: "로또 소개", icon: "mdi-menu-right" , url: "/store/about"},
     // { text: "로또 역사", icon: "mdi-menu-right", url: "/store/history"},
      //{ text: "로또 용어정리", icon: "mdi-menu-right" , url: "/store/words"},
      //{ text: "로또 구입요령", icon: "mdi-menu-right", url: "/store/buyinfo" },
      //{ text: "로또 판매점", icon: "mdi-menu-right" , url: "/store/location"},

      
    ],


  }),
  computed: {
  },
  methods: {
    
  },
};
</script>
