var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-responsive',{staticStyle:{"font-size":"20px","color":"#333"}},[_vm._l((_vm.number),function(num,ii){return _c('v-chip',{key:ii,class:(_vm.$vuetify.breakpoint.smAndUp && _vm.viewtype =='large')
        ? 'itemLottoNumber large'
        : 'itemLottoNumber',style:(num > 40 && _vm.number.find(function (value) { return value === num; })
        ? 'color:#00000070'
        : num > 30 && _vm.number.find(function (value) { return value === num; })
        ? 'color:#00000070'
        : num > 20 && _vm.number.find(function (value) { return value === num; })
        ? 'color:#00000070'
        : num > 10 && _vm.number.find(function (value) { return value === num; })
        ? 'color:#00000070'
        : num <= 10 && _vm.number.find(function (value) { return value === num; })
        ? 'color:#00000070'
        : 'color:#00000070'),attrs:{"value":num,"color":num > 40
        ? '#b0d840'
        : num > 30
        ? '#aaa'
        : num > 20
        ? '#ff7272'
        : num > 10
        ? '#69c8f2'
        : num <= 10
        ? '#fbc400'
        : ''}},[_vm._v(" "+_vm._s(num)+" ")])}),(_vm.bonus)?[_c('v-icon',[_vm._v("mdi-plus")]),_c('v-chip',{class:(_vm.$vuetify.breakpoint.smAndUp && _vm.viewtype =='large')
            ? 'itemLottoNumber large'
            : 'itemLottoNumber',style:(_vm.bonus > 40 
            ? 'color:#00000070'
            : _vm.bonus > 30 
            ? 'color:#00000070'
            : _vm.bonus > 20 
            ? 'color:#00000070'
            : _vm.bonus > 10 
            ? 'color:#00000070'
            : _vm.bonus <= 10 
            ? 'color:#00000070'
            : 'color:#00000070'),attrs:{"value":_vm.bonus,"color":_vm.bonus > 40
            ? '#b0d840'
            : _vm.bonus > 30
            ? '#aaa'
            : _vm.bonus > 20
            ? '#ff7272'
            : _vm.bonus > 10
            ? '#69c8f2'
            : _vm.bonus <= 10
            ? '#fbc400'
            : ''}},[_vm._v(" "+_vm._s(_vm.bonus)+" ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }