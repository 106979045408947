<template>
  <v-app id="inspire"  v-if="$store.state.systemOption">
    <!--
    <v-app-bar app color="white" flat>
      <v-avatar
        :color="$vuetify.breakpoint.smAndDown ? 'grey darken-1' : 'transparent'"
        size="32"
      ></v-avatar>

      <v-tabs centered class="ml-n9" color="grey darken-1">
        <v-tab
          v-for="item in links"
          :key="item"
          :to="{ path: item.url }"
          class="topmenu"
        >
          {{ item.text }}
        </v-tab>
      </v-tabs>
      <v-avatar
        class="hidden-sm-and-down"
        color="grey darken-1 shrink"
        size="32"
      ></v-avatar>
    </v-app-bar>-->

    <Header></Header>

    <router-view />
    <!--
{{$store.state.systemOption.uid}}<br>
{{$store.state.systemOption.uid_chance}} /
{{$store.state.systemOption.service_weeks_get_count}}-->

    <v-divider></v-divider>

    <Footer v-if="$vuetify.breakpoint.lgAndUp"></Footer>
    <FooterMobile
      v-if="
        $vuetify.breakpoint.mdAndDown &&
        this.$route.name != 'Login' &&
        this.$route.name != 'Join'
      "
    ></FooterMobile>

    <v-snackbar
      v-model="$store.state.sb.act"
      :color="$store.state.sb.color"
      centered
    >
      <v-row>
        <v-col cols="auto" class="mr-auto">
          <v-card class="pa-2" outlined tile color="transparent">
            {{ $store.state.sb.msg }}
          </v-card>
        </v-col>
        <v-col cols="auto">
          <v-card outlined tile color="transparent">
            <v-btn color="grey" @click="$store.commit('pop', { act: false })">
              닫기
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-snackbar>

    <AlertDialog
      v-if="alertDialogToggle"
      :dialog="alertDialogToggle"
      :timeout="alertDialogInfo.timeout"
      :emoji="alertDialogInfo.emoji"
      :title="alertDialogInfo.title"
      :firstLineText="alertDialogInfo.firstLineText"
      :secondLineText="alertDialogInfo.secondLineText"
      :thirdLineText="alertDialogInfo.thirdLineText"
      :button1="alertDialogInfo.button1"
      :button1Payload="alertDialogInfo.button1Payload"
      :button1Color="alertDialogInfo.button1Color"
      :button1Class="alertDialogInfo.button1Class"
      :button2="alertDialogInfo.button2"
      :button2Payload="alertDialogInfo.button2Payload"
      :button2Color="alertDialogInfo.button2Color"
      :button2Class="alertDialogInfo.button2Class"
      :persistent="alertDialogInfo.persistent"
    ></AlertDialog>

<div v-if="false">
<hr>
<h1>service_mode</h1>
{{$store.state.systemOption.service_mode}}
<hr>
<h1>service_banner_main</h1>
{{$store.state.systemOption.service_banner_main}}





<hr>
<h1>service_banner_home_top</h1>
{{$store.state.systemOption.service_banner_home_top}}
<hr>
<h1>service_banner_home_bottom</h1>
{{$store.state.systemOption.service_banner_home_bottom}}
<hr>


<h1>service_banner_home_side_top</h1>
{{$store.state.systemOption.service_banner_home_side_top}}
<hr>
<h1>service_banner_home_side_bottom</h1>
{{$store.state.systemOption.service_banner_home_side_bottom}}


<hr>
<h1>service_footer</h1>
{{$store.state.systemOption.service_footer}}
<hr>
<h1>service_faq</h1>
{{$store.state.systemOption.service_faq}}



<hr>
<h1>service_banner_global_header</h1>
{{$store.state.systemOption.service_banner_global_header}}
<hr>
<h1>service_banner_global_sub_top</h1>
{{$store.state.systemOption.service_banner_global_sub_top}}
<hr>
<h1>service_banner_global_sub_bottom</h1>
{{$store.state.systemOption.service_banner_global_sub_bottom}}



<h1>service_banner_global_sub_side_top</h1>
{{$store.state.systemOption.service_banner_global_sub_side_top}}
<hr>
<h1>service_banner_global_sub_side_bottom</h1>
{{$store.state.systemOption.service_banner_global_sub_side_bottom}}

</div>

  </v-app>
</template>

<script>
//import Footer from "./components/Footer";
import { mapGetters } from "vuex";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    //Footer,
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    //...mapGetters(["menuLeft"]),

    ...mapState(["alertDialogToggle", "alertDialogInfo"]),
  },

  methods: {},
};
</script>
