<template>
  <span>
    {{total}}
  </span>
</template>
<script>
export default {
  name: "WidgetMyWonCount",

  props: {
    grade: {required: false, default: null },
    
  },


  
  data: () => ({
    total: 0,    
  }),

  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.getList();
    console.log(this.firstLoad);
  },
  
  methods: {


    getList(page) {
      if (!page) page = this.page;
      this.loading = true;

      this.$http
        .get(
          "/api/v1/number/?page=" +
            page +
            "&count=" +
            1 +
            "&grade=" +
            this.grade 
        )
        .then((res) => {
          console.log(res);

          if (res.status === 200) {

            this.thispage = res.data.thispage;
            this.lastpage = res.data.lastpage;
            this.total = res.data.total;
            this.uid = res.data.uid;
            this.roles = res.data.roles;

            //this.paging = res.data.paging;
            //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

            //this.classes = res.data;
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });

    },
  },
};
</script>
<style scoped>

</style>
