<template>
<v-responsive>
  <v-row class="py-1">
    <v-col class="d-flex align-center" style="color:#333;font-size:17px;font-weight:bold;" v-html="title">
      
    </v-col>
    <v-col>
      
        <v-select
        style="width:90px;float:right;padding:0;font-size:12px;"
          :items="items"

          item-value="drwNo"
          item-text="drwNo"
          v-model="drw"
          return-object

          hide-details
          class="my-2 pa-0 small"
          dense outlined
          small
          max-width="100"
          height="10"
        ></v-select>
        <!--
        <select style="background:#fff;border:1px solid #eee;padding:2px 5px;border-radius:14px;font-size:11px;;">
          <option>975회</option>
          <option>975회</option>
          <option>975회</option>
        </select>
        -->
    </v-col>

  </v-row>
{{drw.drwNo}}
{{drw.totSellamnt}}
{{drw}}

  <v-data-table
    dense
    :headers="headers"
    :items="desserts"
    class="elevation-0"
    hide-default-footer
    :mobile-breakpoint="0" 

  ></v-data-table>
</v-responsive>
</template>
<script>
  export default {
  props: {
    title: String,
  },
    data: () => ({
      desserts: [
        {
          grade: '1등',
          type: '1조합',
          amount: '2,440,410,375원',
        },
        {
          grade: '2등',
          type: '3조합',
          amount: '2,440,410,375원',
        },
        {
          grade: '3등',
          type: '122조합',
          amount: '2,440,410,375원',
        },
        {
          grade: '4등',
          type: '6,672조합',
          amount: '2,440,410,375원',
        },
        {
          grade: '5등',
          type: '12,5413조합',
          amount: '2,440,410,375원',
        },
      ],
      headers: [
        {
          text: '등수',
          align: 'start',
          sortable: false,
          value: 'grade',
          class: "datatable_header"
        },
        { text: '당첨조합', value: 'type', align: 'end', 
          class: "datatable_header",
          sortable: false,},
        { text: '당첨금', value: 'amount' , align: 'end',
          class: "datatable_header",
          sortable: false,},
      ],
      items2: ['975', '974', '973', '972'],


      items: null,
      drw: null,

    }),

      
    mounted() {
      //페이지 시작하면은 자동 함수 실행
      this.getLatestResult();
    },

    
  watch: {
    drw() {
      console.log("watch page");

        this.desserts[0].amount = this.$addCommas(this.drw.firstWinamnt) + '원';

    },
  },




  methods: {
    populateLicense(license) {
        this.trim.license = license
     },
    getLatestResult() {

      this.$axios
        .get(
          "https://lotto7777.iwinv.net/lottoapp/?drwNo=all&mode=get"
        )
        .then((res) => {
          console.log(res);

          this.items = res.data.reverse();
          this.drw = this.items[0];

        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });

    },




  },



  }
</script>
<style scoped>

.itemLottoNumber {
  display: inline-block;
  text-align: center;
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0;
  margin-right: 3px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
}
.itemLottoNumber.large {
  margin: 5px auto;
  height: 45px;
  width: 45px;
  margin-right: 10px;
  border-radius: 50%;
  font-size: 20px;
}
</style>
