<template>
  <div>
    <!--
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="name"
        :counter="10"
        :rules="nameRules"
        label="Name"
        required
      ></v-text-field>

      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="E-mail"
        required
      ></v-text-field>

      <v-select
        v-model="select"
        :items="items"
        label="Item"
      ></v-select>

      <v-checkbox
        v-model="checkbox"
        :rules="[(v) => !!v || 'You must agree to continue!']"
        label="결제 조건을 확인하셨나요?"
        required
      ></v-checkbox>

      <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
        Validate
      </v-btn>

      <v-btn color="error" class="mr-4" @click="reset"> Reset Form </v-btn>

      <v-btn color="warning" @click="resetValidation"> Reset Validation </v-btn>
    </v-form>
-->

    <v-form ref="form">
      <v-select
        v-model="impCode_default"
        :items="impCode"
        label="가맹점 식별코드"
        required
        style="display:none;"
      ></v-select>



        <v-select
          v-model="product"
          :items="product_options"
          item-value="amount"
          item-text="label"
          label="충전 포인트 선택"
          required

          prepend-icon="mdi-currency-krw"
        ></v-select>

      <v-text-field
        ref="inputAmount"
        v-model="order.amount"
        label="충전포인트 입력"
        type="number" step="1000"
        min="1000" max=""
        oninput="setTimeout(() => {javascript: if(parseInt(this.value/1000)) this.value=parseInt(this.value/1000)*1000}, 1000)"
        required
        :readonly="!!product"
        :style="!!product ? 'display:none;' : ''"
      ></v-text-field>

      <v-text-field v-model="order.name" label="고객명" required></v-text-field>
      <v-text-field
        v-model="order.buyer_tel"
        label="전화번호"
        required
      ></v-text-field>
    </v-form>

    <v-btn color="blue" block class="mr-4 white--text" @click="requestPay">
      충전하기
    </v-btn>


    <!--<input v-model="impCode" placeholder="가맹점 식별코드" />
    <input v-model="order.name" placeholder="상품명" />
    <input v-model="order.amount" placeholder="상품가격" />
    <input v-model="order.buyer_tel" placeholder="주문자 전화번호" />

    <button v-on:click="requestPay">결제 요청</button>-->
  </div>
</template>

<script>
//import { PGS } from '../iamport/constants';
//import Utils from '../iamport/utils';

export default {
  name: "PayWindow",

  data: () => ({
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,

    impCode_default: "iamport",
    impCode: ["iamport"],

    product_default: "고정수  8~13개 완전 조합수",
    products: [
      "5000",
      "10000",
      "30000",
      "50000",
      "직접선택",
    ],

    order: {
      name: "홍길동",
      amount: 1000,
      buyer_tel: "01012345678",
    },

      product:null,
      product_options: [
        {
          id: 2,
          amount: 5000,
          label: "5000원",
        },
        {
          id: 3,
          amount: 10000,
          label: "10000원",
        },
        {
          id: 4,
          amount: 30000,
          label: "30000원",
        },
        {
          id: 5,
          amount: 50000,
          label: "50000원",
        },
        {
          id: 6,
          amount: '',
          label: "직접입력",
        },
      ],


  }),
  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.product = this.product_options[1].amount

  },
  watch: {
    product() {
      
        this.order.amount = this.product
      if(!this.product){
         this.$refs.inputAmount.focus()
      }

    },
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    closeDialog: function () {
          this.$emit("close-event",null);
    },
    requestPay: function () {
      //1. 객체 초기화 (가맹점 식별코드 삽입)
      const self = this;
      var IMP = window.IMP;
      IMP.init(this.impCode);
      //3. 결제창 호출
      IMP.request_pay(
        {
          pg: "inicis",
          pay_method: "card",
          merchant_uid: "merchant_" + new Date().getTime(),
          name: this.order.name,
          amount: this.order.amount,
          buyer_tel: this.order.buyer_tel,
        },
        function (rsp) {
          var msg = "";
          if (rsp.success) {
            //4. 결제 요청 결과 서버(자사)에 적용하기
            //ajax 서버 통신 구현 -> 5. 서버사이드에서 validation check

            //6. 최종 서버 응답 클라이언트에서 단계 4.에서 보낸 서버사이드 응답 에따라 결제 성공 실패 출력
            msg = "결제가 완료되었습니다.";
            msg += "고유ID : " + rsp.imp_uid;
            msg += "상점 거래ID : " + rsp.merchant_uid;
            msg += "결제 금액 : " + rsp.paid_amount;
            msg += "카드 승인번호 : " + rsp.apply_num;

            self.closeDialog();


          } else {
            msg = "결제에 실패하였습니다.";
            msg += "에러내용 : " + rsp.error_msg;
          }
          alert(msg);
        }
      );
    },
  },
};
</script>
