<template>
<v-expansion-panels class="mt-5">
                <v-expansion-panel>
                  <v-expansion-panel-header
                    >예상번호를 받으면 자동으로 로또구매가
                    되나요?</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    예상번호를 받으시고 복권구매를 하지 않으시는 경우 고액
                    당첨이 되시더라도 당첨금 수령이 불가하니, 예상번호를 받으신
                    후 복권구입은 직접 로또 판매점에서 구입하셔야 합니다. * 현재
                    인터넷 사이트나 기타 수단을 통해 로또복권을 판매하거나
                    구매대행하는 것은 불법입니다.
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    >문자(SMS, LMS)가 오지 않습니다.</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    최근 각 통신사의 스팸정책에 따라 문자가 수신되지 않을 수
                    있습니다. 회원님들께서는 이용중인 통신사로 전화하셔서
                    스팸필터링 부가서비스를 해지하시면 정상적으로 문자수신이
                    가능하며 스팸필터링 부가서비스 해지를 원치 않으신
                    고객님들께서는 스팸필터링 허용번호등록에 고객센터 번호를
                    입력해주시기 바랍니다. 그 밖에 휴대폰 자체에 SMS
                    스팸차단기능으로 고객센터 번호가 스팸번호로 등록이
                    되어있는지 확인해주시고, 후후/후스콜등 스팸차단 어플에서도
                    저희 고객센터 번호가 스팸등록되어있는지 확인부탁드립니다. *
                    문자 수신요일에 휴대폰을 꺼두시거나 수신이 약한 지역에
                    계셨다면 문자수신이 안될수도 있으니, 문자를 받지 못한 경우
                    고객센터로 연락주시면 재발송 도움 드리겠습니다. 문자로
                    전송되신 번호들은 홈페이지 상단메뉴의 [마이페이지] ->
                    [문자발송내역] 페이지에서도 확인하실 수 있으니 이용에
                    참고하시기 바랍니다.
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    >조합 번호를 나눠서 SMS 전송을 받고
                    싶어요.</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    현재 수신요일을 나누어서 자동으로 추천 조합을 발송해
                    드릴수는 없습니다. 만약 추천 조합을 나누어서 사용하시려면
                    수신요일 전에 홈페이지 접속후 상단의 [빅데이터 추출번호] 를
                    통해 수동으로 사용하시면 해당 조합을 문자(MMS)로 보내드리고
                    있습니다. 위 방법을 이용하시면 추천 조합을 나눠서 받아보실
                    수 있으니 참고하여 주시기 바랍니다. 감사합니다.
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    >무료 추천조합 5조합은 언제
                    제공되나요?</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    무료 추천 조합은 전체회원님 대상으로 매주 5조합이 무료
                    제공됩니다. 이 무료 추천조합은 골드회원과는 달리 문자 전송은
                    이루어지지 않으며, 골드대박조합시스템과 다르게 기본필터링
                    시스템을 거친 조합들이 발송됩니다. 확률 높은 골드대박조합을
                    받는것을 추천드립니다. 무료 추천조합은 매주 화요일에서
                    목요일 5조합씩 순차적으로 발송되며, 마이로또를 통해 확인
                    하실 수 있습니다. 무료추천조합 발송시간: 화요일 [새벽1시
                    부터 5조합 발송] 로그인 후 상단메뉴 중 [마이페이지]페이지를
                    통해 조합받으신 번호를 확인가능하며, 실제 로또복권으로
                    구매해 주시면 되겠습니다.
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    >예상번호 문자 전송시간은
                    언제인가요?</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    문자 전송시간은 [오전 09:00 ~ 오후 13:00] 사이에 전송이
                    이루어 집니다. 문자 전송시간은 1등예측 시시스템 업데이트로
                    요일에 따라서 변경 될 수 있습니다. 또한 문자로 전송되신
                    번호들은 홈페이지 [마이페이지]] 에서도 확인하실 수 있으니
                    이용에 참고하시기 바랍니다.
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
</template>
<script>
export default {

  data: () => ({
    
      show: false,

  }),
  computed: {
  },
  methods: {
    
  },
};
</script>
